import React, { useState } from 'react';
import ScoreCard from './ScoreCard';
import MyBet from './MyBet';


const EventSideBar = () => {
  const [expanded, setExpanded] = useState<string | true>('panel1');


  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : true);
  };

  return (
    <div>
        <ScoreCard expanded={true} handleAccordionChange={handleAccordionChange}/>
      <MyBet expanded={true} handleAccordionChange={handleAccordionChange}/>
    </div>
  );
};

export default EventSideBar;