import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField, Button, Typography, Box } from "@mui/material";
import { FormProps } from "./type/type";
import { toast } from "react-toastify";
import { useApi } from "../../hooks/useApi";
import { PASSWORD_CHANGE_URL } from "../../utils/apiUrl";

const validationSchema = Yup.object().shape({
  masterPassword: Yup.string().required("Master Password is required"),
  newPassword: Yup.string().required("New Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
    .required("Confirm Password is required"),
});
type PasswordFormData = Yup.InferType<typeof validationSchema>;

const PasswordChangeForm: React.FC<FormProps> = ({ userData, onClose }) => {
  const initialValues = {
    masterPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const { isLoading, error, sendRequest } = useApi();
  const handleSubmit = async (values: PasswordFormData) => {
    // Handle form submission here
    try {
      const result = await sendRequest(PASSWORD_CHANGE_URL, "POST", {
        user_id: userData.userId,
        master_password: values.masterPassword,
        new_password: values.newPassword,
        confirm_password: values.confirmPassword,
      });
      if (result.status!==200) {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 5000,
        });
      } else {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 5000,
        });
      }

      onClose();
    } catch (err: any) {
      console.log(error);
      toast.error(error, {
        position: "top-center",
        delay: 2000,
      });
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Change Password
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Field
              as={TextField}
              name="masterPassword"
              label="Master Password"
              type="password"
              fullWidth
              margin="normal"
              error={touched.masterPassword && !!errors.masterPassword}
              helperText={touched.masterPassword && errors.masterPassword}
            />
            <Field
              as={TextField}
              name="newPassword"
              label="New Password"
              type="password"
              fullWidth
              margin="normal"
              error={touched.newPassword && !!errors.newPassword}
              helperText={touched.newPassword && errors.newPassword}
            />
            <Field
              as={TextField}
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              fullWidth
              margin="normal"
              error={touched.confirmPassword && !!errors.confirmPassword}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={isSubmitting}
              sx={{
                mt: 2,
                bgcolor: "#b8b44e",
                "&:hover": { bgcolor: "#a09f45" },
              }}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PasswordChangeForm;
