import React, { FC, ChangeEvent, useState } from 'react';
import { Box, FormControlLabel, Switch, SwitchProps } from '@mui/material';
import styled from '@emotion/styled';

interface ToggleButtonProps {
  label: string;
  initialValue?: boolean;
  onChange?: (value: boolean) => void;
  locked?: boolean;
}
const ButtonContainer =styled('div')(({theme})=>({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
}))
const ToggleButton: FC<ToggleButtonProps> = ({
  label,
  initialValue = false,
  onChange,
  locked = false,
}) => {
  const [checked, setChecked] = useState<boolean>(initialValue);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!locked) {
      const newValue = event.target.checked;
      setChecked(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  return (

<Box>
<Switch
// sx={{display:'flex',justifyContent:'center',alignItems:'center'}}
          checked={checked}
          onChange={handleChange}
          color="primary"
          name={label}
          inputProps={{ 'aria-label': label }}
          disabled={locked}
        />
</Box>


  );
};

export default ToggleButton;