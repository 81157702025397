import { GET_PROFIT_LOSS_USERWISE_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { MRT_ColumnDef } from 'material-react-table';

const PLUserWise: React.FC = () => {
  const PLUserWiseColumns = [
    { accessorKey: 'sport_name', header: 'Sport' },
    { accessorKey: 'match_name', header: 'Match' },
    { accessorKey: 'user_name', header: 'User' },
    { accessorKey: 'id', header: 'User Id' },
    { accessorKey: 'result', header: 'Result' },
    {
      accessorKey: 'stack',
      header: 'Stack',
    },
    {
      accessorKey: 'down_pl',
      header: 'Down P/L',
      Cell: ({ cell }: { cell: { getValue: () => number } }) => {
        const value = cell.getValue();
        const color = value < 0 ? 'red' : 'green';
        return <span style={{ color }}>{value}</span>;
      },
    },
    {
      accessorKey: 'user_pl',
      header: 'User P/L',
      Cell: ({ cell }: { cell: { getValue: () => number } }) => {
        const value = cell.getValue();
        const color = value < 0 ? 'red' : 'green';
        return <span style={{ color }}>{value}</span>;
      },
    },
    {
      accessorKey: 'up_pl',
      header: 'Up P/L',
      Cell: ({ cell }: { cell: { getValue: () => number } }) => {
        const value = cell.getValue();
        const color = value < 0 ? 'red' : 'green';
        return <span style={{ color }}>{value}</span>;
      },
    },
    {
      accessorKey: 'user_commission',
      header: 'Commission',
    },
    {
      accessorKey: 'created_at',
      header: 'Date',
      Cell: ({ row }: any) => formatDate(row.original.created_at),
    },
  ];

  const defaultPLUserWiseSettings = {
    start_date: '',
    end_date: '',
    user_id: '',
  };

  return (
    <CommonList
          title="Profit Loss User Wise"
          fetchUrl={GET_PROFIT_LOSS_USERWISE_URL}
          itemType="report-pl-user-wise"
          columns={PLUserWiseColumns as MRT_ColumnDef<Record<string, any>, unknown>[]}
          defaultSettings={defaultPLUserWiseSettings}
          searchRequestType="POST"
          addUrl=""
          updateSettingsUrl=""
        />
  );
};

export default PLUserWise;