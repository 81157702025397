import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import { useApi } from "../../hooks/useApi";
import {
  BET_HISTORY_MARKET_ANALYSIS_URL,
  BET_HISTORY_URL,
} from "../../utils/apiUrl";
import { th } from "date-fns/locale";
import theme from "../../utils/customTheme";
import { useNavigate } from "react-router-dom";

interface Runner {
  selection_id: string;
  selection_name: string;
  is_back: string;
  p_l: string;
  bets_count: number;
}

interface MatchData {
  market_id: any;
  sport_name: any;
  match_id: string;
  match_name: string;
  runners: Runner[];
  total_bets: number;
}

const MarketAnalysis = () => {
  const [matches, setMatches] = useState<MatchData[]>([]);
  const { isLoading, error, sendRequest } = useApi();
  const navigate = useNavigate();
  const fetchBetHistory = async () => {
    try {
      const response = await sendRequest(
        BET_HISTORY_MARKET_ANALYSIS_URL,
        "POST",
        {
          user_id: JSON.parse(
            sessionStorage.getItem("adminUserDetails") || "{}"
          ).id,
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setMatches(response.data);
      }
    } catch (error) {
      console.error("Error fetching bet history:", error);
    }
  };

  useEffect(() => {
    fetchBetHistory();
    const interval = setInterval(fetchBetHistory, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Market Analysis
      </Typography>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            {matches.map((match) => (
              <Grid item xs={12} sm={6} md={6} key={match.match_id}>
                <Card
                  sx={{
                    height: "100%",
                    backgroundColor: theme.palette.background.paper,
                  }}
                  onClick={() =>
                    navigate(
                      `/sports/${match.sport_name}/${match.market_id}/${match.match_id}`
                    )
                  }
                >
                  <CardContent>
                    <Typography variant="h6">{match.match_name}</Typography>

                    <Typography variant="body2">
                      Total Bets: {match.total_bets}
                    </Typography>
                    {match.runners.length > 0 &&
                      match.runners.map((runner) => (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            backgroundColor: "#EEEEEE",
                            padding: 1,
                            borderRadius: 1,
                            justifyContent: "space-between",
                            marginBottom: '10px',
                          }}
                        >
                          <React.Fragment key={runner.selection_id}>
                            <Typography variant="body2">
                              {runner.selection_name}
                            </Typography>
                            <Typography variant="body2">
                              {runner.bets_count}
                            </Typography>
                          </React.Fragment>
                        </Box>
                      ))}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {matches.length === 0 && !isLoading && (
            <Typography variant="body1" sx={{ textAlign: "center", my: 4 }}>
              No records found
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default MarketAnalysis;
