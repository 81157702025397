import React, { useState } from 'react'
import { 
    AccordionDetails,
    Typography,
    Switch,
    styled,
    Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { StyledAccordion, StyledAccordionSummary } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWebSocketSelector } from '../../../hooks/useWebSocket';

const ScoreCard = ({expanded, handleAccordionChange}: {expanded: boolean, handleAccordionChange: any}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentEventId = location.pathname.split('/')[3];
  const { marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )

  const [liveTV, setLiveTV] = useState(true);

  const currentMarketData = marketIds[currentEventId];

  if (!currentMarketData) {
    return <div>Loading...</div>;
  }

  const renderLiveTV = () => {
    let tvUrl = currentMarketData?.tv_url;
    if (tvUrl.includes('https://dtv.diamondapi.uk/ctv/index.php')) {
      tvUrl = tvUrl.replace('https://dtv.diamondapi.uk/ctv/index.php', 'https://dtv.diamondapi.uk/ctv/index.html');
    }
    return (
      <iframe
        src={tvUrl}
        style={{ width: '100%', height: '180px', border: 'none', overflow: 'hidden' }}
        title="Live TV"
      />
    );
  };

  const renderScoreBoard = () => {
    let scoreUrl = currentMarketData?.score_url;
    if (scoreUrl.includes('https://diamondapi.uk/dcasino/graph.php')) {
      scoreUrl = scoreUrl.replace('https://diamondapi.uk/dcasino/graph.php', 'https://172.235.1.180/dcasino/graph.php');
    }
    return (
      <iframe
        src={scoreUrl}
        style={{ width: '100%', minHeight: '180px', border: 'none', overflow: 'hidden' }}
        title="Score Board"
      />
    );
  };

  const matchName = currentMarketData.match_name.split('v');

  return (
    <StyledAccordion expanded={expanded} onChange={handleAccordionChange('panel1')}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon sx={{color:'white'}} />}>
        <Typography>Match Details</Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <div style={{ marginTop: '12px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography sx={{ fontSize: "1.5rem" }}>{matchName[0]}</Typography>
            <Typography sx={{ fontSize: "1rem" }}>v/s</Typography>
            <Typography sx={{ fontSize: "1.5rem" }}>{matchName[1]}</Typography>
          </div>
          <div>
            <Switch
              size="small"
              onChange={() => setLiveTV(!liveTV)}
              checked={liveTV}
            />
            <Typography sx={{ fontSize: "12px" }}>Watch Live</Typography>
          </div>
        </div>
        {liveTV ? renderLiveTV() : renderScoreBoard()}
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default ScoreCard