import React, { 
  createContext, 
  useContext, 
  useCallback, 
  useEffect, 
  useState, 
  useMemo, 
  ReactNode 
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { io, Socket } from 'socket.io-client'

import { debounce } from 'lodash'
// import { useNavigate } from 'react-router-dom'
import { ActiveMatches, BALANCE_URL } from '../utils/apiUrl'
import { connect, resetWebSocketState, setIsConnected, setWebSocketData, updateGameOdds, updateMarketIds } from '../store/websocketSlice'
import { useNavigate } from 'react-router-dom'

// Define types for context and props
interface WebSocketContextType {
  sendMessage: (eventName: string, data: any) => void;
  isConnected: boolean;
  connect: () => void;
  subscribeToMarket: (marketId: string[], matchId: string, isEventScreen: boolean) => void;
  unsubscribeFromMarket: (marketId: string[]) => void;
  marketsIdsArray: any[];
  fancyValues: any[];
  marketOdds: any;
  bookmakerOdds: any;
  updatedMarketDetails: any;
}

const WebSocketContext = createContext<WebSocketContextType | null>(null)

interface WebSocketProviderProps {
  children: ReactNode;
}

const WebSocketProviderComponent: React.FC<WebSocketProviderProps> = ({ children }) => {
  const dispatch = useDispatch()
  const [marketsIdsArray, setMarketsIdsArray] = useState<any[]>([])
  const [fancyValues, setFancyArray] = useState<any[]>([])
  const [marketOdds, setMarketOdds] = useState<any>({})
  const [bookmakerOdds, setBookmakerOdds] = useState<any>({})
  const [updatedMarketDetails, setUpdatedMarketDetails] = useState<any>({})
  const { isConnected } = useSelector((state: any) => state.websocket)
  const [socket, setSocket] = useState<Socket | null>(null)

  //function to fetch balance
  const fetchBalance = useCallback(async () => {
    try {
      const adminUserDetails = JSON.parse(sessionStorage.getItem('adminUserDetails') || '{}')
      const token = adminUserDetails.token

      // Do not check for balance if token is not there
      if (!token) {
        return
      }

      const response = await fetch(BALANCE_URL, {
        headers: {
          "accept": "application/json, text/plain, */*",
          "authorization": token,
          "content-type": "application/json"
        },
        body: JSON.stringify({ name: "kkk" }),
        method: "POST",
        mode: "cors",
        credentials: "include"
      });
      if(response.status === 401){
        sessionStorage.removeItem('adminUserDetails');
        sessionStorage.removeItem('token');
         window.location.href = '/login';
    
        // Check if page has been reloaded before
        
      }
      if (!response.ok) {
        throw new Error('Failed to fetch balance');
      }
    

      const data = await response.json();

      // Update adminUserDetails in sessionStorage with new balance
      const updatedadminUserDetails = { ...adminUserDetails, ...data?.data?.[0] };
      sessionStorage.setItem('adminUserDetails', JSON.stringify(updatedadminUserDetails));
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  }, []);

  // Set up interval to fetch balance
  useEffect(() => {
    if (sessionStorage.getItem('adminUserDetails')) {
      const balanceInterval = setInterval(fetchBalance, 2000); // Fetch every 2 seconds
      return () => {
        clearInterval(balanceInterval);
      };
    }
  }, [fetchBalance, dispatch]);

  const parseMarketIds = (marketValues: any) => {
    setMarketsIdsArray(prev => {
      const marketIdx = prev.findIndex(i => i.market_id === marketValues.market_id);
      if (marketIdx === -1) {
        return [...prev, marketValues]
      } else {
        return prev.map((item, index) =>
          index === marketIdx ? { ...item, ...marketValues } : item
        );
      }
    })
  }

  const parseFancyValues = (fancyValues: any) => {
    setFancyArray(prev => {
      const fancyIdx = prev.findIndex(i => i.id === fancyValues.id);

      if (fancyIdx === -1) {
        return [...prev, fancyValues]
      } else {
        return prev.map((item, index) =>
          index === fancyIdx ? { ...item, ...fancyValues } : item
        );
      }
    })
  }

  const debouncedUpdateMarketIds = useMemo(
    () => debounce((data) => dispatch(updateMarketIds(data)), 500),
    [dispatch],
  )

  const debouncedUpdateGameOdds = useCallback(
    (data: any)=>dispatch(updateGameOdds(data)),
    [dispatch]
  )

  const debouncedSetWebSocketData = useMemo(
    () => debounce((data) => dispatch(setWebSocketData(data)), 500),
    [dispatch],
  )
  const fetchActiveMatches = useCallback(async () => {
    try {

      const response = await fetch(ActiveMatches);
      if (!response.ok) {
        throw new Error('Failed to fetch active matches');
      }

      const data = await response.json();
      
      debouncedUpdateMarketIds(data.data)
    } catch (error) {
      console.error('Error fetching active matches:', error);
    }
  }, [debouncedUpdateMarketIds]);

  useEffect(() => {
    // if (!JSON.parse(sessionStorage.getItem('userDetails') || '{}').token) {
      fetchActiveMatches();
    // }
  }, [fetchActiveMatches]);

  const connectWebSocket = useCallback(() => {
    const adminUserDetails = JSON.parse(sessionStorage.getItem('adminUserDetails') || '{}')
    const token = adminUserDetails.token || null
    if (token && !isConnected) {
      const url = window.ENV_CONFIG?.REACT_APP_WEBSOCKET_HOST || process.env.REACT_APP_WEBSOCKET_HOST;
      const newSocket = io(`${url}`, {
        path: '/api/socket',
        transports: ['websocket'],
        upgrade: false,
        query: { token },
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 10000,
        timeout: 2000000,
      })

      newSocket.on('connect', () => {
        dispatch(setIsConnected(true))
        newSocket.emit('event', {
          key: 'match-with-match_odds',
          action: 'all',
          param: {
            isActive: '1',
            sportId: '',
          },
          token,
        })
      })

      newSocket.on('disconnect', () => {
        dispatch(setIsConnected(false))
      })

      newSocket.on('error', (error) => {
        dispatch(setIsConnected(false))
      })

      newSocket.on('message', (message) => {
        const { key, data } = message
        if (key === 'match') {
          debouncedUpdateMarketIds(data)
        } else if (key === 'fancy') {
          parseFancyValues(JSON.parse(data || '{}'))
        }  else if (key === 'odds') {
          const parsedData = JSON.parse(data || '{}')
          parseMarketIds(parsedData)
          
          setBookmakerOdds((prevOdds: any) => ({
            ...prevOdds,
            [parsedData.market_id]: parsedData
          }))
          setMarketOdds(parsedData)
        } else if (key === 'market') {
          setUpdatedMarketDetails(data)
        }
        debouncedSetWebSocketData(data)
      })

      setSocket(newSocket)

      dispatch(connect())
    }
  }, [
    dispatch,
    isConnected,
    setIsConnected,
    debouncedUpdateMarketIds,
    debouncedUpdateGameOdds,
    debouncedSetWebSocketData,
  ])

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  }, [connectWebSocket]);

  

  const subscribeToMarket = useCallback(
    (marketId: string[], matchId: string, isEventScreen: boolean) => {      if (socket) {
        const adminUserDetails = JSON.parse(
          sessionStorage.getItem('adminUserDetails') || '{}',
        )
        const token = adminUserDetails.token

        if (isEventScreen) {
          socket.emit('event', {
            action: 'all',
            key: 'market',
            param: {
              matchId,
            },
            token,
          })

          socket.emit('event', {
            action: 'all',
            key: 'bet',
            param: {
              isActive: "1",
              matchId,
            },
            token,
          })

          // Handle 'market' key message
          socket.on('message', (message) => {
            if (message.key === 'market') {
              const selectedGameMarketIds = message.data?.markets?.map((i: any) => i.market_id);
              if (selectedGameMarketIds && selectedGameMarketIds.length > 0) {
                socket.emit('event', {
                  action: 'subscribe',
                  key: 'subscribe',
                  param: {
                    ids: selectedGameMarketIds,
                  },
                  token,
                })
              }
            }
          })
        } else {
          socket.emit('event', {
            action: 'subscribe',
            key: 'subscribe',
            param: {
              ids: [...marketId],
            },
            token,
          })
        }
      }
    },
    [socket],
  )

  const unsubscribeFromMarket = useCallback(
    (marketId: string[]) => {
      if (socket) {
        const adminUserDetails = JSON.parse(
          sessionStorage.getItem('adminUserDetails') || '{}',
        )
        const token = adminUserDetails.token
        socket.emit('event', {
          key: 'unsubscribe',
          action: 'subscribe',
          param: {
            ids: [...marketId],
          },
          token,
        })
      }

           // Reset WebSocket context states
           setMarketsIdsArray([])
           setFancyArray([])
           setMarketOdds({})
           setBookmakerOdds({})
           setUpdatedMarketDetails({})
          //  dispatch(resetWebSocketState())
          //  connectWebSocket()

    },
    [socket],
  )

  const sendMessage = useCallback(
    (eventName: string, data: any) => {
      if (socket) {
        socket.emit(eventName, data)
      }
    },
    [socket],
  )

  const value = useMemo(
    () => ({
      sendMessage,
      isConnected,
      connect: connectWebSocket,
      subscribeToMarket,
      unsubscribeFromMarket,
      marketsIdsArray,
      fancyValues,
      marketOdds,
      bookmakerOdds,
      updatedMarketDetails,
    }),
    [marketsIdsArray,
      sendMessage,
      updatedMarketDetails,
      isConnected,
      connectWebSocket,
      subscribeToMarket,
      unsubscribeFromMarket,
      fancyValues,
      marketOdds,
      bookmakerOdds
    ],
  )

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  )
}

export const WebSocketProvider = React.memo(WebSocketProviderComponent)

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext)
  if (context === null) {
    throw new Error(
      'useWebSocketContext must be used within a WebSocketProvider',
    )
  }
  return context
}

export const useWebSocketSelector = (selector: (state: any) => any) => {
  return useSelector(selector, (prev, next) => JSON.stringify(prev) === JSON.stringify(next))
}
