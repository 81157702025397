import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "./Logo";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

type MenuOption = { name: string; path: string };

const settings: string[] = ["Profile", "Account", "Dashboard", "Logout"];
export interface NavBarProps {
  handleOpenMenu?: () => void;
}

const ResponsiveAppBar: React.FC<NavBarProps> = ({ handleOpenMenu }) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [adminUserDetails, setadminUserDetails] = useState({
    name: "Guest",
    balance: "0.00"
  });

  useEffect(() => {
    const updateadminUserDetails = () => {
      const details = JSON.parse(sessionStorage.getItem("adminUserDetails") || "{}");
      setadminUserDetails({
        name: details.name || "Guest",
        balance: details.balance || "0.00"
      });
    };

    updateadminUserDetails(); // Initial update
    const intervalId = setInterval(updateadminUserDetails, 10000); // Update every 10 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const handleOpenNavMenu = handleOpenMenu;
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleMenuItemClick = (path: string) => {
    navigate(path);
  };

  const username = adminUserDetails.name;
  const balance = adminUserDetails.balance;

  const renderMenu = (
    anchorEl: null | HTMLElement,
    handleClose: () => void,
    options: MenuOption[] | string[]
  ) => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MenuItem disabled>
        <AccountCircleIcon sx={{ mr: 1 }} />
        <Typography textAlign="center">{username}</Typography>
      </MenuItem>
      <MenuItem disabled>
        <AttachMoneyIcon sx={{ mr: 1 }} />
        <Typography textAlign="center">Balance: {balance}</Typography>
      </MenuItem>
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 1 }} />
      {options.map((option) => (
        <MenuItem
          key={typeof option === "string" ? option : option.name}
          onClick={() => {
            handleClose();
            if (typeof option !== "string") handleMenuItemClick(option.path);
            if (option === 'Logout') {
              sessionStorage.removeItem('adminAuthToken');
              sessionStorage.removeItem('adminUserDetails');
              navigate('/login');
            }
          }}
        >
          <Typography textAlign="center">
            {typeof option === "string" ? option : option.name}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: "#212124" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "flex",lg:"flex" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Logo size="1.7rem" />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={username} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            {renderMenu(anchorElUser, handleCloseUserMenu, settings)}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
