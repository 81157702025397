import { Typography } from '@mui/material';
import { GET_PROFIT_LOSS_SPORT_WISE_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { MRT_ColumnDef } from 'material-react-table';
import { format, subDays } from 'date-fns';
const PLSportWise: React.FC = () => {
    const PLSportWiseColumns = [
        { accessorKey: 'sport_name', header: 'Sport' },
        // { accessorKey: 'series_name', header: 'Series' },
        { accessorKey: 'match_name', header: 'Match' },
        { accessorKey: 'user_name', header: 'User' },
        { accessorKey: 'result', header: 'Result' },
        {
          accessorKey: 'stack',
          header: 'Stack',
        },
        {
          accessorKey: 'down_pl',
          header: 'Down P/L',
          Cell: ({ cell }: { cell: { getValue: () => number } }) => {
            const value = cell.getValue();
            const color = value < 0 ? 'red' : 'green';
            return <span style={{ color }}>{value}</span>;
          },
        },
        {
          accessorKey: 'user_pl',
          header: 'User P/L',
          Cell: ({ cell }: { cell: { getValue: () => number } }) => {
            const value = cell.getValue();
            const color = value < 0 ? 'red' : 'green';
            return <span style={{ color }}>{value}</span>;
          },
        },
        {
          accessorKey: 'up_pl',
          header: 'Up P/L',
          Cell: ({ cell }: { cell: { getValue: () => number } }) => {
            const value = cell.getValue();
            const color = value < 0 ? 'red' : 'green';
            return <span style={{ color }}>{value}</span>;
          },
        },
        {
          accessorKey: 'user_commission',
          header: 'Commission',
        },
        {
          accessorKey: 'created_at',
          header: 'Date',
          Cell: ({ row }: any) => formatDate(row.original.created_at),
        },
      ];
      const today = format(new Date(), 'yyyy-MM-dd');
      const TenDaysAgoDate = format(subDays(new Date(), 10), 'yyyy-MM-dd');

  const defaultPLSportWiseSettings = {
    start_date: '',
    end_date: '',
    sport_id: '',
  };

  return (
    <CommonList
          title="Profit Loss Sport Wise"
          fetchUrl={GET_PROFIT_LOSS_SPORT_WISE_URL}
          itemType="report-pl-sport-wise"
          columns={PLSportWiseColumns as MRT_ColumnDef<Record<string, any>, unknown>[]}
          defaultSettings={defaultPLSportWiseSettings}
          searchRequestType="POST"
          addUrl=""
          updateSettingsUrl=""
          enablePagination={false}
        />
  );
};

export default PLSportWise;