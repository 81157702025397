import { Box, Button, IconButton, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { LIST_RESULT_URL, ADD_MATCH_URL, UPDATE_MATCH_SETTING_URL, DECLARE_RESULT_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { toast } from 'react-toastify';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { format, subDays } from 'date-fns';

const ResultMarketPage: React.FC = () => {
  const [selectedResults, setSelectedResults] = useState<{ [key: string]: string }>({});
  const { sendRequest } = useApi();

  const handleResultChange = (id: string, value: string) => {
    setSelectedResults(prev => ({ ...prev, [id]: value }));
  };

  const handleDeclareResult = async (row: any) => {
    try {
      const result = await sendRequest(DECLARE_RESULT_URL, 'POST', {
        "selection_id": selectedResults[row?.id],
        "market_id": row?.market_id,
        "result_type": "1"
    });
      if (result.status === 200) {
        toast.success('Result declared successfully', {
          position: 'top-center',
          autoClose: 2000
        });
        // Optionally, refresh the data or update the local state
      } else {
        toast.error(result.message || 'Failed to declare result', {
          position: 'top-center',
          autoClose: 5000
        });
      }
    } catch (error) {
      toast.error('An error occurred while declaring the result', {
        position: 'top-center',
        autoClose: 5000
      });
    }
  };

  const handleClearResult = (id: string) => {
    setSelectedResults(prev => {
      const newState = { ...prev };
      delete newState[id];
      return newState;
    });
  };

  const matchColumns = [
    { accessorKey: 'sport_name', header: 'Sport' },
    { accessorKey: 'series_name', header: 'Series' },
    { accessorKey: 'match_name', header: 'Match' },
    { accessorKey: 'name', header: 'Market Name' },
    {
      accessorKey: 'date',
      header: 'Date',
      Cell: ({ row }: any) => (
        <Typography>{formatDate(row.original.created_at)}</Typography>
      ),
    },
    {
      accessorKey: 'result',
      header: 'Result',
      Cell: ({ row }: any) => {
        const runners = row.original.runner_json
        return (
          <Select
            value={selectedResults[row.original.id] || ''}
            onChange={(e) => handleResultChange(row.original.id, e.target.value as string)}
            displayEmpty
            size="small"
          >
            <MenuItem value="" disabled>Select Result</MenuItem>
            {runners.map((i: any) => {
              return (<MenuItem value={i.id}>{i.name}</MenuItem>)
            })}
            {/* Add more result options as needed */}
          </Select>
        )
      },
    },
    {
      accessorKey: 'action',
      header: 'Action',
      Cell: ({ row }: any) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: 2 }}>
          <Tooltip title="Declare Result">
            <span>
              <IconButton
                color="primary"
                onClick={() => handleDeclareResult(row.original)}
                disabled={!selectedResults[row.original.id]}
                size="large"
              >
                <CheckCircle sx={{ color: 'green', cursor: 'pointer' }} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Clear Result">
            <span>
              <IconButton
                color="primary"
                onClick={() => handleClearResult(row.original.id)}
                disabled={!selectedResults[row.original.id]}
                size="large"
              >
                <Cancel sx={{ color: 'red', cursor: 'pointer' }} />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const today = format(new Date(), 'yyyy-MM-dd');
  const yesterday = format(subDays(new Date(), 10), 'yyyy-MM-dd');
  const defaultMatchSettings = {
      "sport_id": "",
      "series_id": "",
      "match_id": "",
      "list_type": "0",
      "search": "",
      "start_date": yesterday,
      "end_date": today,
  };

  return (
    <CommonList
      title="Result Market"
      fetchUrl={LIST_RESULT_URL}
      addUrl={ADD_MATCH_URL}
      updateSettingsUrl={UPDATE_MATCH_SETTING_URL}
      itemType='result-market'
      columns={matchColumns}
      defaultSettings={defaultMatchSettings}
      searchRequestType={'POST'}
    />
  );
};

export default ResultMarketPage;