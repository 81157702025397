import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Switch, IconButton } from '@mui/material';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import BaseTable from '../BaseTable';
import { useApi } from '../../hooks/useApi';
import { UPDATE_MARKETS_STATUS_URL, UPDATE_MATCH_STATUS_URL, UPDATE_SERIES_STATUS_URL, UPDATE_SPORT_SETTING_URL } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { Settings } from '@mui/icons-material';

const UPDATE_URLS = {
  market: UPDATE_MARKETS_STATUS_URL,
  match: UPDATE_MATCH_STATUS_URL,
  series: UPDATE_SERIES_STATUS_URL,
  sport: UPDATE_SPORT_SETTING_URL,
};

interface CommonTableProps<T extends Record<string, any>> {
  data: T[];
  columns: MRT_ColumnDef<T>[];
  pagination: MRT_PaginationState;
  totalItems: number;
  onPaginationChange: (updaterOrValue: MRT_PaginationState | ((prevState: MRT_PaginationState) => MRT_PaginationState)) => void;
  onSettingsClick: (itemId: string) => void;
  itemType: string;
  filterOptions?: any;
  showFilter?: boolean;
  selectedIndex: number | null;
  setSelectedIndex: (index: number | null) => void;
  enablePagination?: boolean;
}

const CommonTable = <T extends Record<string, any>>({
  data,
  columns,
  pagination,
  totalItems,
  onPaginationChange,
  onSettingsClick,
  itemType,
  setSelectedIndex,
  enablePagination = true,
}: CommonTableProps<T>) => {
  const { isLoading, error, sendRequest } = useApi();
  const [localData, setLocalData] = useState<T[]>([]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const getIdField = (itemType: string) => {
    switch (itemType) {
      case 'market':
        return 'market_id';
      case 'match':
        return 'match_id';
      case 'series':
        return 'series_id';
      case 'sport':
        return 'sport_id';
      default:
        return 'id';
    }
  }

  const toggleStatus = useCallback(async (id: string, currentStatus: string) => {
    const newStatus = currentStatus === "1" ? "0" : "1";
    const updateUrl = UPDATE_URLS[itemType as keyof typeof UPDATE_URLS];

    const adminUserDetails = JSON.parse(sessionStorage.getItem('adminUserDetails') || '{}');
    const userId = adminUserDetails.id;

    if (!userId) {
      toast.error('User ID not found in sessionStorage', {
        position: 'top-center',
        autoClose: 2000
      });
      return;
    }

    const payload = {
      [getIdField(itemType)]: id,
      status: newStatus,
      user_id: userId
    };

    const result = await sendRequest(updateUrl, 'POST', payload);

    if (result.status === 200) {
      toast.success(result.message, {
        position: 'top-center',
        autoClose: 2000
      });
      setLocalData(prevData =>
        prevData.map(item =>
          item[getIdField(itemType)] === id ? { ...item, is_active: newStatus } : item
        )
      );
    } else {
      toast.error(result.message, {
        position: 'top-center',
        autoClose: 5000
      });
    }
  }, [sendRequest, itemType]);

  const tableColumns: MRT_ColumnDef<T>[] = [
    ...columns
  ];
  // Add the status column only if itemType is not a result type
  if (!itemType.startsWith('result-') && !itemType.startsWith('report-')) {
    tableColumns.push({
      accessorKey: 'is_active',
      header: 'Status',
      enableSorting: false,
      Cell: ({ cell, row }) => {
        const isActive = cell.getValue<string | number>() === "1" || cell.getValue<string | number>() === 1;
        return (
          <Switch
            checked={isActive}
            onChange={() => toggleStatus(row.original[getIdField(itemType)], isActive ? "1" : "0")}
            disabled={isLoading}
            color="primary"
          />
        );
      },
    });

    tableColumns.push({
      accessorKey: 'actions',
      header: 'Actions',
      enableSorting: false,
      Cell: ({ row }) => (
        <IconButton
          onClick={() => {
            setSelectedIndex(row.index);
            onSettingsClick(row.original[getIdField(itemType)]);
          }}
          size="small"
        >
          <Settings />
        </IconButton>
      ),
    })
  }

  const handleFilterClick = (criteria: any) => {
    console.log(criteria);
  }
  return (
    <>
      {error && <Typography color="error">{error}</Typography>}
      <BaseTable<T>
        columns={tableColumns}
        data={localData}
        enableTopToolbar={true}
        enableBottomToolbar={true}
        enableColumnFilters={true}
        enableGlobalFilter={true}
        enablePagination={enablePagination}
        manualPagination={true}
        onPaginationChange={onPaginationChange}
        pageCount={localData.length > 0 ? Math.ceil(totalItems / pagination.pageSize) : 0}
        rowCount={totalItems}
        state={{ pagination }}
        title={''}
      />
    </>
  );
};

export default CommonTable;