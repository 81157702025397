import React, { useMemo, useState, useCallback, useEffect, useImperativeHandle, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { navMenuList } from "../../constants/navMenuList";
import Clock from "../Clock";
import { useWebSocketSelector } from "../../hooks/useWebSocket";
// Add these imports
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Icon } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

const Backdrop = styled("div")<{ open: boolean }>(({ theme,open }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 2,
  display: open ? "block" : "none",
  [theme.breakpoints.up("lg")]: {
    width: '0px' // Swap these values
  },
}));

interface SideBarLeftProps {
  toggled?: boolean;
  onClose: () => void; // Add this line
  
  [key: string]: any;
}

// Define a ref type for the component
export interface SideBarLeftRef {
  resetSubMenus: () => void;
}

const SidebarContainer = styled("div")<{ toggled: boolean }>(
  ({ theme, toggled }) => ({
    position: "fixed",
    height: "100%",
    zIndex: 2,
    fontFamily: '"Lato", "Helvetica Neue", sans-serif',
    transition: "width 0.3s ease",
    width: toggled ? "65vw" : 0,
    background: `linear-gradient(135deg, ${theme.palette.bgColor} 0%, ${theme.palette.bgColor}CC 100%)`,
    backdropFilter: "blur(10px)",
    WebkitBackdropFilter: "blur(10px)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
    border: "1px solid rgba(255, 255, 255, 0.18)",
    [theme.breakpoints.up("lg")]: {
      width: toggled ? "17vw" : "4vw", // This line is causing the issue
    },
  })
);

const SidebarInner = styled("div")<{ toggled: boolean }>(
  ({ theme, toggled }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      display: toggled ? "flex" : "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex", // Always display flex on desktop
    },
  })
);

const SidebarContent = styled("div")({
  flexGrow: 1,
  overflowY: "auto",
  padding: "10px",
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    outline: "1px solid slategrey",
  },
});

const ClockContainer = styled("div")({
  padding: "10px",
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
});

const MenuItemHeader = styled('div')({
  display:'flex',
  alignItems:'center',
  gap:'10px',
  justifyContent:'space-between'
})
const SidebarMenu = styled("nav")({
  color: "white",
});

const MenuItem = styled("div")(({ theme }) => ({
  padding: "0.75rem 0.5rem",
  cursor: "pointer",
  fontWeight: 400,
  fontSize: "1rem",
  "&:hover": {
    fontWeight: 500,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  "&.top-level": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const SubMenuTitle = styled("div")({

  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const SubMenuContent = styled("div")({
  paddingLeft: "1rem",
});

const MenuItemContent = styled('div')<{ toggled: boolean }>(({ theme, toggled }) => ({
  display: 'flex',
  alignItems: "center",
  justifyContent:'space-between',
  [theme.breakpoints.up('lg')]: {
    justifyContent: toggled ? 'space-between' : 'space-between',
  
  },
}));

const MenuItemText = styled('span')<{ toggled: boolean }>(({ theme, toggled }) => ({
  [theme.breakpoints.up('lg')]: {
    display: toggled ? 'inline' : 'none',
  },
}));

const SideBarLeft = forwardRef<SideBarLeftRef, SideBarLeftProps>(({
  toggled = false,
  onClose,

  ...restProps
},ref):JSX.Element=>{
  const navigate = useNavigate();
  const [openSubMenus, setOpenSubMenus] = useState<string[]>([]);
  const { marketIds } = useWebSocketSelector((state) => state.websocket);
  const isUser =
    JSON.parse(sessionStorage.getItem("adminUserDetails") || "{}")
      ?.user_type_id === 10
      ? true
      : false;

  const handleNavigation = (path: string) => {
    if (path !== "#") {
      navigate(path);
    }
  };
  const resetSubMenus = useCallback(() => {
    setOpenSubMenus([]);
  }, []);

  // Expose the resetSubMenus function via ref
  useImperativeHandle(ref, () => ({
    resetSubMenus
  }));

  const toggleSubMenu = (id: string) => {
    setOpenSubMenus((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };
 


  const groupedMenuArray = useMemo(() => {
    return _.chain(marketIds)
      .filter((market) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const matchDate = new Date(market.date);
        matchDate.setHours(0, 0, 0, 0);
        return matchDate >= today;
      })
      .groupBy("sport_name")
      .map((data, key) => ({
        gameType: key,
        totalMatches: _.uniqBy(data, "match_id").length,
        seriesNames: _.chain(data)
          .uniqBy("match_id")
          .groupBy("series_name")
          .map((seriesData, seriesKey) => ({
            seriesName: seriesKey,
            allMatches: seriesData,
          }))
          .value(),
      }))
      .value();
  }, [marketIds]);

  const renderMenuItems = useMemo(() => {
    return groupedMenuArray.map((el) => (
      <React.Fragment key={el.gameType}>
        <Tooltip title={!toggled ? el.gameType : ''} placement="right">
          <MenuItem>

            <MenuItemContent toggled={toggled} onClick={() => {if(!toggled){onClose(); };toggleSubMenu(el.gameType)}}>
              <MenuItemHeader>
              <Icon>{el.gameType.charAt(0)}</Icon>
              <MenuItemText toggled={toggled}>{el.gameType}</MenuItemText>
              </MenuItemHeader>
              {toggled && (openSubMenus.includes(el.gameType) ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
            </MenuItemContent>
          
          </MenuItem>
        </Tooltip>
        {openSubMenus.includes(el.gameType) && (
          <SubMenuContent>
            {el.seriesNames.map((elSeries) => (
              <MenuItem
                key={elSeries.seriesName}
                className="submenu"
                sx={{ fontSize: "12px" }}
              >
                <SubMenuTitle
                  onClick={() => toggleSubMenu(elSeries.seriesName)}
                >
                  {elSeries.seriesName}
                  {openSubMenus.includes(elSeries.seriesName) ? (
                    <ExpandMoreIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </SubMenuTitle>
                {openSubMenus.includes(elSeries.seriesName) && (
                  <SubMenuContent>
                    {elSeries.allMatches.map((elMatch) => (
                      <MenuItem
                        key={elMatch.match_id}
                        onClick={() =>
                          handleNavigation(
                            `/sports/${elMatch.sport_name?.toLowerCase()}/${
                              elMatch.market_id
                            }/${elMatch.match_id}`
                          )
                        }
                        sx={{ fontSize: "12px" }}
                      >
                        {elMatch.match_name}
                      </MenuItem>
                    ))}
                  </SubMenuContent>
                )}
              </MenuItem>
            ))}
          </SubMenuContent>
        )}
      </React.Fragment>
    ));
  }, [groupedMenuArray, openSubMenus, handleNavigation,toggled,onClose]);

  return (
    <>
      <Backdrop open={toggled} onClick={onClose} />
      
      <SidebarContainer toggled={toggled} {...restProps}>
        <SidebarInner toggled={toggled}>
          <SidebarContent>
            {/* {!toggled && (
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginBottom: "10px",
              }}>
                <Clock />
              </div>
            )} */}
            
            <SidebarMenu>
              {!isUser &&
                navMenuList.map((el) => {
                  if (el.hasOwnProperty("subMenu")) {
                    return (
                      <React.Fragment key={el.id}>
                        <Tooltip title={!toggled ? el.name : ''} placement="right">
                        <MenuItem>
                            <MenuItemContent toggled={toggled} onClick={() =>{if(!toggled){onClose(); };toggleSubMenu(el.id.toString())}}>
                         <MenuItemHeader>
                              {el.icon && <Icon component={el.icon} fontSize="small" />}
                              <MenuItemText toggled={toggled}>{el.name}</MenuItemText>
                       </MenuItemHeader>
                              {toggled && (openSubMenus.includes(el.id.toString()) ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
                            </MenuItemContent>
                           
                       
                          </MenuItem>
                        </Tooltip>
                        {openSubMenus.includes(el.id.toString()) && (
                          <SubMenuContent>
                            {el.subMenu?.map((subEl) => (
                              <MenuItem
                                key={subEl.id}
                                onClick={() => handleNavigation(subEl.path)}
                              >
                                {subEl.name}
                              </MenuItem>
                            ))}
                          </SubMenuContent>
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <Tooltip key={el.id} title={!toggled ? el.name : ''} placement="right">
                        <MenuItem
                          onClick={() => handleNavigation(el.path)}
                          className="submenu"
                        >
                          <MenuItemContent toggled={toggled}>
                          <MenuItemHeader>
                            {el.icon && <Icon component={el.icon} fontSize="small" />}
                            <MenuItemText toggled={toggled}>{el.name}</MenuItemText>
                          </MenuItemHeader>
                           
                          </MenuItemContent>
                        </MenuItem>
                      </Tooltip>
                    );
                  }
                })}

              {renderMenuItems}
            </SidebarMenu>
          </SidebarContent>
        </SidebarInner>
      </SidebarContainer>
    </>
  );
})

export default React.memo(SideBarLeft);
