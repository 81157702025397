import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, useRef } from 'react'
import { Grid, LinearProgress, styled, useMediaQuery,useTheme } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'
import {Theme} from '@mui/material/styles'
import NavBar, { NavBarProps } from '../Navbar'
import SideBarLeft, { SideBarLeftRef } from '../SideBarLeft/SideBarLeft'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const PaddingGrid = styled(Grid)({
  // paddingTop: '64px'
})

const MemoizedNavBar = React.memo<NavBarProps>(NavBar)
const MemoizedSideBarLeft = React.memo(SideBarLeft)

function RootLayout() {
  const theme = useTheme<Theme>()
  console.log(theme)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
const [isSideBarOpen,setIsSideBarOpen]=useState(false)
const navigate = useNavigate();
const sideBarRef = useRef<SideBarLeftRef>(null);

const resetSideBarSubMenus = useCallback(() => {
  if (sideBarRef.current) {
    sideBarRef.current.resetSubMenus();
  }
}, []);
  const toggleSidebar = useCallback(()=>{
    resetSideBarSubMenus();
    setIsSideBarOpen(prev=>!prev)
  }, [isSideBarOpen,setIsSideBarOpen,resetSideBarSubMenus])
  const token = sessionStorage.getItem('adminAuthToken');
 
 
  const mobileLayout = useMemo(() => (
    <>
      <MemoizedNavBar handleOpenMenu={toggleSidebar} />
      <PaddingGrid container spacing={2} >
        <Grid item xs={12}  sx={{zIndex:1200}} >
        <MemoizedSideBarLeft toggled={isSideBarOpen} breakPoint='always' onBackdropClick={toggleSidebar} onClose={toggleSidebar}/>
          <Outlet />
        </Grid>
      </PaddingGrid>
    </>
  ), [toggleSidebar,isDesktop])


  const desktopLayout = useMemo(() => (
    <div style={{ display: 'flex', width: '100vw', height: '100vh' }}>
      <div style={{ width: isSideBarOpen ? '17vw' : '4vw',  transition: 'width 0.3s' }}>
        <MemoizedSideBarLeft 
          ref={sideBarRef}
          toggled={isSideBarOpen} 
          onClose={toggleSidebar} 
        />
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <MemoizedNavBar handleOpenMenu={toggleSidebar} />
        <div style={{ flex: 1, overflow: 'auto' }}>
          <Outlet />
        </div>
      </div>
    </div>
  ), [isSideBarOpen, toggleSidebar])

   useEffect(()=>{
  if(!token){
    navigate('/login')
  }
  },[token,navigate])
 
  return (
    <>
    <ToastContainer/>
      {/* {isLoading && (
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      {hasError && (
        <div>Connection error. Please check your internet connection.</div>
      )} */}
      {!isDesktop ? mobileLayout : desktopLayout}
    </>
  )
}

export default React.memo(RootLayout)
