import React, { useEffect, useState } from 'react';
import { useApi } from '../hooks/useApi';
import { Box, Card, CardContent, Typography, Grid, Avatar, Container, Skeleton, styled } from '@mui/material';
import { AccountBalanceWallet, Casino, SportsSoccer, Group, CardGiftcard, CreditCard } from '@mui/icons-material';
import { StyledToolbar } from './commonList/CommonList';
import { DASHBOARD_URL } from '../utils/apiUrl';

interface DashboardData {
  profit_losses: {
    casino_pl: number | null;
    sport_pl: number | null;
  };
  child_data: {
    referred_amount: number;
    reward_amount: number;
    bonus_amount: number;
    total_bonus: number;
    lock_bonus: number;
    comm_pl: number;
    downline_pl: number;
  };
  user: {
    user_name: string;
    name: string;
    balance: number;
    credit_limit: number;
    total_balance: number;
    down_line_balance: number;
  };
  total_market_lock_user: number;
  total_fancy_lock_user: number;
  total_user: number;
  total_lock_user: number;
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'box-shadow 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const Dashboard: React.FC = () => {
  const { sendRequest } = useApi();
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      const adminUserDetails = JSON.parse(sessionStorage.getItem('adminUserDetails') || '{}');
      const userId = adminUserDetails.id;

      try {
        const response = await sendRequest(
          DASHBOARD_URL,
          'POST',
          { user_id: userId }
        );
        setDashboardData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const renderSkeleton = () => (
    <Skeleton variant="rectangular" width="100%" height={118} />
  );

  const renderContent = (content: React.ReactNode) => (
    isLoading ? renderSkeleton() : content
  );

  return (
    <Container maxWidth={false} sx={{ py: 3 }}>
      <StyledToolbar>
        <Typography variant="h4">Dashboard</Typography>
      </StyledToolbar>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {/* Profit & Loss Section */}
        <Grid item xs={12} lg={8}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Profit & Loss</Typography>
              <Grid container spacing={2}>
                {['Sports P&L', 'Casino P&L', 'Own Profit', 'Downline P&L'].map((item, index) => (
                  <Grid item xs={12} sm={6} md={3} key={item}>
                    {renderContent(
                      <Box display="flex" alignItems="center">
                        {index === 0 && <SportsSoccer sx={{ mr: 1 }} />}
                        {index === 1 && <Casino sx={{ mr: 1 }} />}
                        {index === 2 && <AccountBalanceWallet sx={{ mr: 1 }} />}
                        {index === 3 && <Group sx={{ mr: 1 }} />}
                        <Box>
                          <Typography variant="subtitle2">{item}</Typography>
                          <Typography variant="h6">
                            {index === 0 && dashboardData?.profit_losses?.sport_pl}
                            {index === 1 && dashboardData?.profit_losses?.casino_pl}
                            {index === 2 && dashboardData?.user?.balance}
                            {index === 3 && dashboardData?.child_data?.downline_pl}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* User Section */}
        <Grid item xs={12} lg={4}>
          <StyledCard>
            <CardContent>
              {renderContent(
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Avatar sx={{ width: 60, height: 60, mb: 2 }}>{dashboardData?.user?.name[0]}</Avatar>
                  <Typography variant="h6">{dashboardData?.user?.name}</Typography>
                  <Typography variant="body2">Total Users: {dashboardData?.total_user}</Typography>
                  <Typography variant="body2">Active Users: {dashboardData && (dashboardData.total_user - dashboardData.total_lock_user)}</Typography>
                  <Typography variant="body2">Locked Users: {dashboardData?.total_lock_user}</Typography>
                  <Typography variant="body2">Market Locked Users: {dashboardData?.total_market_lock_user}</Typography>
                  <Typography variant="body2">Fancy Locked Users: {dashboardData?.total_fancy_lock_user}</Typography>
                </Box>
              )}
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Balance Section */}
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Balance</Typography>
              {renderContent(
                <Grid container spacing={2}>
                  {['Credit Limit', 'Total Balance', 'Own Balance', 'Downline Balance'].map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={item}>
                      <Typography variant="body2">
                        {item}: {
                          index === 0 ? dashboardData?.user?.credit_limit :
                          index === 1 ? dashboardData?.user?.total_balance :
                          index === 2 ? dashboardData?.user?.balance :
                          dashboardData?.user?.down_line_balance
                        }
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              )}
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Bonus & Reward Section */}
        {/* <Grid item xs={12} md={8}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Bonus & Reward</Typography>
              {renderContent(
                <Grid container spacing={2}>
                  {['Total Bonus', 'Deposit Bonus', 'Bonus Lock', 'Refer Bonus'].map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={item}>
                      <Typography variant="body2">
                        {item}: {
                          index === 0 ? dashboardData?.child_data?.total_bonus :
                          index === 1 ? dashboardData?.child_data?.bonus_amount :
                          index === 2 ? dashboardData?.child_data?.lock_bonus :
                          dashboardData?.child_data?.referred_amount
                        }
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              )}
            </CardContent>
          </StyledCard>
        </Grid> */}

        {/* Additional Cards */}
        {/* <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            {['Scratch Card', 'Lossback Bonus'].map((item, index) => (
              <Grid item xs={6} key={item}>
                <StyledCard>
                  <CardContent>
                    {renderContent(
                      <Box display="flex" alignItems="center">
                        {index === 0 ? <CardGiftcard sx={{ mr: 1 }} /> : <CreditCard sx={{ mr: 1 }} />}
                        <Box>
                          <Typography variant="subtitle2">{item}</Typography>
                          <Typography variant="h6">0</Typography>
                        </Box>
                      </Box>
                    )}
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default Dashboard;