import { Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import EventList from './EventList'
import SideBarLeft from '../SideBarLeft/SideBarLeft'
import EventSideBar from './eventSideBar'
import { useWebSocketContext, useWebSocketSelector } from '../../hooks/useWebSocket'
import { useLocation } from 'react-router-dom'

function MatchOdds() {
  const { subscribeToMarket, unsubscribeFromMarket } = useWebSocketContext();
  const location = useLocation();

  const { marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )

  const currentMarketId = location.pathname.split('/')[3];
  const currentEventDetails = marketIds[currentMarketId] || {}


  useEffect(() => {
    if(currentEventDetails?.match_id) {
      subscribeToMarket([currentMarketId],currentEventDetails?.match_id, true) //subscribing to market
      return unsubscribeFromMarket([currentMarketId]);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarketId, currentEventDetails?.match_id])

  return (
    <Grid container spacing={2} padding={'4px 8px 4px 8px'}>
      <Grid item lg={7} >
        <EventList />
      </Grid>
      <Grid item lg={5}>

        <EventSideBar />
      </Grid>
    </Grid>
  )
}

export default MatchOdds