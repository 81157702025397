import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Chip,
  useTheme,
  Modal,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import BaseTable from '../BaseTable';
import { useApi } from '../../hooks/useApi';
import { DEPOSIT_WITHDRAWAL_REQUEST_LIST_URL, DEPOSIT_WITHDRAWAL_REQUEST_UPDATE_URL } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { ContentCopy, Visibility } from '@mui/icons-material';
import { format, subDays } from 'date-fns';
import { StyledToolbar } from '../commonList/CommonList';

interface DepositWithdrawRequest {
  id: number;
  user_name: string;
  amount: string;
  type: string;
  status: string;
  created_at: string;
  utr: string;
  account_holder: string;
  account_number: string;
  ifsc: string;
  bank_name: string;
  branch_name: string;
  method: string;
}

const DepositWithdrawRequests: React.FC = () => {
  const theme = useTheme();
  const { sendRequest } = useApi();
  const [data, setData] = useState<DepositWithdrawRequest[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchUser, setSearchUser] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [startDate, setStartDate] = useState<string>(format(subDays(new Date(), 7), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<DepositWithdrawRequest | null>(null);
  const [message, setMessage] = useState('');

  const fetchData = async () => {
    try {
      const response = await sendRequest(DEPOSIT_WITHDRAWAL_REQUEST_LIST_URL, 'POST', {
        type: selectedType,
        status: selectedStatus,
        start_date: startDate,
        end_date: endDate,
        per_page: pagination.pageSize,
        page: pagination.pageIndex + 1,
        user_id: JSON.parse(sessionStorage.getItem('adminUserDetails') || '{}').id
      });

      if (response.status === 200) {
        setData(response.data.data);
        setTotalItems(response.data.meta.total);
      } else {
        toast.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('An error occurred while fetching data');
    }
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000); // 5 seconds in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleSearch = () => {
    fetchData();
  };

  const handleViewDetails = (request: DepositWithdrawRequest) => {
    setSelectedRequest(request);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRequest(null);
    setMessage('');
  };

  const handleUpdateStatus = async (status: 'Success' | 'Declined') => {
    if (!selectedRequest) return;

    try {
      const response = await sendRequest(DEPOSIT_WITHDRAWAL_REQUEST_UPDATE_URL, 'POST', {
        id: selectedRequest.id,
        status,
        message,
      });

      if (response.status === 200) {
        toast.success(`Request ${status.toLowerCase()} successfully`);
        fetchData();
        handleCloseModal();
      } else {
        toast.error('Failed to update request status');
      }
    } catch (error) {
      console.error('Error updating request status:', error);
      toast.error('An error occurred while updating request status');
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  const columns: MRT_ColumnDef<DepositWithdrawRequest>[] = [
    {
      accessorKey: 'id',
      header: '#',
      size: 50,
      Cell: ({ cell }) => (
        <Box display="flex" alignItems="center">
          <Typography>{cell.getValue<number>()}</Typography>
          <Tooltip title="Copy ID">
            <IconButton onClick={() => copyToClipboard(cell.getValue<number>().toString())}>
              <ContentCopy fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      accessorKey: 'user_name',
      header: 'User Name',
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      Cell: ({ cell }) => (
        <Typography color={cell.row.original.type === '1' ? 'success.main' : 'error.main'}>
          {cell.getValue<string>()}
        </Typography>
      ),
    },
    {
      accessorKey: 'type',
      header: 'Type',
      Cell: ({ cell }) => (
        <Chip
          label={cell.getValue<string>() === '1' ? 'Deposit' : 'Withdrawal'}
          color={cell.getValue<string>() === '1' ? 'success' : 'error'}
        />
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ cell }) => (
        <Chip
          label={cell.getValue<string>()}
          color={
            cell.getValue<string>() === 'Pending'
              ? 'warning'
              : cell.getValue<string>() === 'Success'
              ? 'success'
              : 'error'
          }
        />
      ),
    },
    {
      accessorKey: 'created_at',
      header: 'Date',
      Cell: ({ cell }) => format(new Date(cell.getValue<string>()), 'dd-MM-yyyy HH:mm:ss'),
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      Cell: ({ row }) => (
        <Box>
          <Tooltip title="Copy UTR">
            <IconButton onClick={() => copyToClipboard(row.original.utr)}>
              <ContentCopy />
            </IconButton>
          </Tooltip>
          {row.original.status === 'Pending' && (
            <Tooltip title="View Details">
              <IconButton onClick={() => handleViewDetails(row.original)}>
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <StyledToolbar>
        <Typography variant="h4"> Deposit Withdrawal Request</Typography>
      </StyledToolbar>
      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Search User"
              value={searchUser}
              onChange={(e) => setSearchUser(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Select
              fullWidth
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">All Types</MenuItem>
              <MenuItem value="1">Deposit</MenuItem>
              <MenuItem value="2">Withdrawal</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Select
              fullWidth
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Success">Success</MenuItem>
              <MenuItem value="Declined">Declined</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              fullWidth
            >
              Load
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <BaseTable
        columns={columns}
        data={data}
        enableTopToolbar={true}
        enableBottomToolbar={true}
        enableColumnFilters={true}
        enableGlobalFilter={false}
        enablePagination={true}
        manualPagination={true}
        onPaginationChange={setPagination}
        rowCount={totalItems}
        state={{ pagination }}
      />
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Card sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <CardContent>
            <Typography variant="h6" component="div" gutterBottom>
              Request Details
            </Typography>
            {selectedRequest && (
              <>
                <Typography>ID: {selectedRequest.id}</Typography>
                <Typography>User: {selectedRequest.user_name}</Typography>
                <Typography>Amount: {selectedRequest.amount} INR</Typography>
                <Typography>Type: {selectedRequest.type === '1' ? 'Deposit' : 'Withdrawal'}</Typography>
                <Typography>Method: {selectedRequest.method}</Typography>
                <Typography>UTR: {selectedRequest.utr}</Typography>
                <Typography>Account Holder: {selectedRequest.account_holder}</Typography>
                <Typography>Account Number: {selectedRequest.account_number}</Typography>
                <Typography>IFSC: {selectedRequest.ifsc}</Typography>
                <Typography>Bank Name: {selectedRequest.bank_name}</Typography>
                <Typography>Branch Name: {selectedRequest.branch_name}</Typography>
              </>
            )}
            <TextField
              fullWidth
              multiline
              rows={3}
              margin="normal"
              label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </CardContent>
          <CardActions>
            <Button onClick={() => handleUpdateStatus('Success')} variant="contained" color="primary">
              Success
            </Button>
            <Button onClick={() => handleUpdateStatus('Declined')} variant="contained" color="error">
              Declined
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </Box>
  );
};

export default DepositWithdrawRequests;