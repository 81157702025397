import { Typography } from '@mui/material';
import { ACCOUNT_STATEMENT_URL, BET_HISTORY_URL } from '../../utils/apiUrl';
import CommonList from '../commonList/CommonList';
import { formatDate } from '../../utils/formatDate';
import { MRT_ColumnDef } from 'material-react-table';
import { subDays, format } from 'date-fns';

const BetHistory: React.FC = () => {
  const BetHistoryColumns = [
    { accessorKey: 'user_name', header: 'User Name' },
    { accessorKey: 'sport_name', header: 'Sport' },
    { accessorKey: 'series_name', header: 'Series' },
    { accessorKey: 'match_name', header: 'Match' },
    { accessorKey: 'market_name', header: 'Market' },
    { accessorKey: 'selection_name', header: 'Selection' },
    {
      accessorKey: 'odds',
      header: 'Odds',
    },
    {
      accessorKey: 'stack',
      header: 'Stack',
    },
    {
      accessorKey: 'p_l',
      header: 'P/L',
    },
    {
      accessorKey: 'liability',
      header: 'Liability',
    },
    {
      accessorKey: 'is_back',
      header: 'Back/Lay',
      Cell: ({ cell }: { cell: { getValue: () => string } }) => cell.getValue() === '0' ? 'Lay' : 'Back',
    },
    {
      accessorKey: 'created_at',
      header: 'Date',
      Cell: ({ row }: any) => formatDate(row.original.created_at),
    },
  ];

  const today = new Date();
  const oneWeekAgo = subDays(today, 10);

  const defaultBetHistorySettings = {
    start_date: format(oneWeekAgo, 'yyyy-MM-dd'),
    end_date: format(today, 'yyyy-MM-dd'),
    result_id: 'current',
    user_name: '',
    user_id: '',
    page: 1,
    items: 50,

    is_fancy: '',
    delete_status: '',
    is_back: '',
    sport_id: '',
    win_status: '',
    total_items: 0,
  };

  return (
    <CommonList
      title="Bet History"
      fetchUrl={BET_HISTORY_URL}
      itemType="report-bet-history"
      columns={BetHistoryColumns as MRT_ColumnDef<Record<string, any>, unknown>[]}
      defaultSettings={defaultBetHistorySettings}
      searchRequestType="POST"
      addUrl=""
      updateSettingsUrl=""
    />
  );
};

export default BetHistory;
