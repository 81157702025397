import CreditLimitForm from "./CreditLimit";
import DepositBonusForm from "./DepositBonusFrom";
import DepositForm from "./DepositForm";
import PasswordChangeForm from "./PasswordChangeForm";
import TransactionForm from "./TransactionForm";
import UserBetLockModal from "./UserBetLockModal";
import WithdrawForm from "./WithdrawForm";
import WithdrawlBonusForm from "./WithdrawlBonusForm";

type ModalComponentType = React.ComponentType<any>;

interface ModalContentType {
  [key: string]: ModalComponentType;
}
export const BlankModalContent = ()=>{
    return <div>
        Modal Component
    </div>
}
export const ModalContent:ModalContentType ={
    CL:CreditLimitForm,
    D:DepositForm,
    W:WithdrawForm,
    DB:DepositBonusForm,
    WB:WithdrawlBonusForm,
    ST:BlankModalContent,
    CP:PasswordChangeForm,
    UB:UserBetLockModal,
}