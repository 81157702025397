import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Paper,
    Tab,
    Tabs,
    TextField,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useApi } from '../../hooks/useApi';
import { BANNER_API_URLS, REACT_APP_FRONTEND_HOST } from '../../utils/apiUrl';
import { CustomCarousel } from './CustomCarousel';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledToolbar } from '../commonList/CommonList';

const CommonPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    margin: theme.spacing(2),
}));

const CommonTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const CommonTabs = styled(Tabs)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const CommonTab = styled(Tab)(({ theme }) => ({
    // Add any custom styles for tabs here
}));

export const CommonTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const CommonButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

interface BannerFormValues {
    img: any;
    banner_type: string;
    url: string;
    details: string;
}

const initialValues: BannerFormValues = {
    img: null,
    banner_type: '',
    url: '',
    details: '',
};

const validationSchema = Yup.object({
    url: Yup.string().url('Invalid URL'),
    details: Yup.string(),
});

interface Banner {
    img: any;
    id: number;
    url: string;
    detail: string;
    banner_type: string;
    img_url: string;
}


const BannerManage: React.FC = () => {
    const [tabValue, setTabValue] = useState(0);
    const theme = useTheme();
    const { sendRequest, isLoading, error } = useApi();
    const [banners, setBanners] = useState<Banner[]>([]);
    const [message, setMessage] = useState<{ severity: 'success' | 'error' | 'info', text: string } | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void) => {
        const file = event.target.files?.[0];
        if (file) {
            setFieldValue("img", file);
            // Optional: Display file name
        }
    };
    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await sendRequest(BANNER_API_URLS.LIST, 'POST', {});
            if (response && response.data) {
                setBanners(response.data);
            }
        } catch (err) {
            setMessage({ severity: 'error', text: 'Failed to fetch banners' });
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleSubmit = async (values: BannerFormValues) => {
        try {
            const formData = new FormData();
            formData.append('banner_type', values.banner_type || 'display');
            formData.append('url', values.url || '');
            formData.append('detail', values.details || '');
            if (values.img instanceof File) {
                formData.append('img', values.img, values.img.name);
            }
            const token = sessionStorage.getItem('adminAuthToken')
            const response = await fetch(BANNER_API_URLS.CREATE, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `${token}`,
                },
                credentials: 'include', // if you need to include cookies
            });

            const result = await response.json();

            if (response.ok) {
                toast.success('Banner added successfully', {
                    position: 'top-center',
                    autoClose: 2000
                });
                await fetchBanners(); // Refresh the banner list after adding a new banner
            } else {
                toast.error(result.message || 'Failed to add banner', {
                    position: 'top-center',
                    autoClose: 5000
                });
            }
        } catch (err) {
            toast.error('An error occurred while adding the banner', {
                position: 'top-center',
                autoClose: 5000
            });
            console.error(err);
        }
    };

    const handleDelete = async (id: number) => {
        try {
            const result = await sendRequest(BANNER_API_URLS.DELETE, 'POST', { id });
            if (result.status === 200) {
                toast.success('Banner deleted successfully', {
                    position: 'top-center',
                    autoClose: 2000
                });
                await fetchBanners(); // Refresh the banner list after deleting a banner
            } else {
                toast.error(result.message || 'Failed to delete banner', {
                    position: 'top-center',
                    autoClose: 5000
                });
            }
        } catch (err) {
            toast.error('An error occurred while deleting the banner', {
                position: 'top-center',
                autoClose: 5000
            });
        }
    };

    return (
        <CommonPaper>
            <StyledToolbar>
                <Typography variant="h4">Banner Manage</Typography>
            </StyledToolbar>
            <CommonTabs
                value={tabValue}
                onChange={handleTabChange}
                centered
            >
                <CommonTab label="Display Banner" />
                <CommonTab label="Top Bonus Banner" />
                <CommonTab label="Bottom Bonus Banner" />
            </CommonTabs>
            {isLoading && <Typography color="info.main">Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}


            <Box sx={{ mb: 4 }}>
                <CommonTypography variant="h6" gutterBottom>
                    Current Banners
                </CommonTypography>
                {banners.length > 0 ? (
                    <CustomCarousel>
                        {banners.map((banner) => (
                            <Card key={banner.id}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={`${REACT_APP_FRONTEND_HOST}/uploads/${banner.img}`}
                                    alt={banner.detail}
                                />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        {banner.detail}
                                    </Typography>
                                    <CommonButton
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleDelete(banner.id)}
                                        sx={{ mt: 1 }}
                                    >
                                        Delete
                                    </CommonButton>
                                </CardContent>
                            </Card>
                        ))}
                    </CustomCarousel>
                ) : (
                    <Typography variant="body1" color="text.secondary" align="center">
                        No banners available. Add a new banner to get started.
                    </Typography>
                )}
            </Box>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, setFieldValue, values }) => {
                    return (
                        <Form>
                            <Field
                                as={CommonTextField}
                                name="url"
                                label="URL"
                                fullWidth
                                variant="outlined"
                                error={touched.url && errors.url}
                                helperText={touched.url && errors.url}
                            />
                            <Field
                                as={CommonTextField}
                                name="details"
                                label="Details"
                                fullWidth
                                variant="outlined"
                                error={touched.details && errors.details}
                                helperText={touched.details && errors.details}
                            />
                            <Box
                                sx={{
                                    border: `2px dashed ${theme.palette.divider}`,
                                    borderRadius: 2,
                                    p: 3,
                                    mb: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileUpload(e, setFieldValue)}
                                />
                                <CommonButton
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    Upload Banner
                                </CommonButton>
                                {/* Optional: Display selected file name */}
                                {values.img && (
                                    <Typography variant="body2" sx={{ mt: 2 }}>
                                        Selected file: {values.img.name}
                                    </Typography>
                                )}
                            </Box>
                            <CommonButton
                                type="submit"
                                variant="contained"
                                fullWidth
                                disabled={isLoading}
                            >
                                Add Banner
                            </CommonButton>
                        </Form>
                    )
                }}
            </Formik>
        </CommonPaper>
    );
};

export default BannerManage;