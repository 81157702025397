import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  styled,
  useTheme,
  Paper,
  Grid,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Snackbar,
  Alert
} from '@mui/material';
import { Edit, Delete, AddCircleOutline, Close } from '@mui/icons-material';
import { MRT_ColumnDef } from 'material-react-table';
import BaseTable from '../BaseTable';
import { StyledToolbar } from '../commonList/CommonList';
import { useApi } from '../../hooks/useApi';
import { ADD_BANK_DETAILS_URL, LIST_BANK_DETAILS_URL } from '../../utils/apiUrl';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const MethodButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '15px',
  borderRadius: '10px',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

interface BankDetail {
  id: number;
  name: string;
  account_number: string;
  confirm_account_number: string;
  ifsc: string;
  qr_code: string;
  account_holder: string;
  bank_name: string;
  branch_name: string;
  bank_id: number;
}

interface WithdrawalMethod {
  name: string;
  image: string;
  bankId: number;
}

const withdrawalMethods: WithdrawalMethod[] = [
  { name: 'Bank Transfer', image: 'https://st.softgamings.com/uploads/BankTransfer_logo_1200x600.png', bankId: 1 },
  { name: 'Paytm', image: 'https://miro.medium.com/v2/resize:fill:320:214/1*04xD0gseEr_g4wA7NLQ5iw.png', bankId: 2  },
  { name: 'PhonePe', image: 'https://cdn.zeebiz.com/sites/default/files/styles/zeebiz_850x478/public/2023/02/14/227260-phonepe1.png?itok=MW54Zukx', bankId: 3 },
  { name: 'GPay', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq_S1I-kRo8EmqBe0MSfTSRdtAflozeeDoBQ&s', bankId: 4 },
  { name: 'UPI', image: 'https://m.economictimes.com/thumb/msid-74960608,width-1200,height-900,resizemode-4,imgsize-49172/upi-twitter.jpg', bankId: 5 },
];

const FullWidthTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    display: 'flex',
  },
  '& .MuiTab-root': {
    flex: 1,
  },
});

const BankDetails: React.FC = () => {
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useApi();
  const [bankDetails, setBankDetails] = useState<BankDetail[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [qrCode, setQrCode] = useState<File | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<string>('Bank Transfer');
  const [bankName, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    fetchBankDetails(withdrawalMethods.find(m => m.name === selectedMethod)?.bankId || 1);
  }, [selectedMethod]);

  const fetchBankDetails = async (bankId: number) => {
    try {
      const response = await sendRequest(LIST_BANK_DETAILS_URL, 'POST', {
        bank_id: bankId,
        page: 1,
        items: 50,
        total_items: 0
      });
      if (response.status === 200 && response.data) {
        setBankDetails(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching bank details:', error);
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setAccountNumber('');
    setConfirmAccountNumber('');
    setAccountName('');
    setIfscCode('');
    setBankName('');
    setBranchName('');
    setQrCode(null);
  };

  const handleSubmit = async () => {
    const selectedBank = withdrawalMethods.find(m => m.name === selectedMethod);
    if (!selectedBank) return;

    try {
      const token = JSON.parse(sessionStorage.getItem('adminUserDetails') || '{}').token;
      let response;

      if (selectedMethod === 'Bank Transfer') {
        response = await fetch(ADD_BANK_DETAILS_URL, {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
            'Accept': 'application/json, text/plain, */*',
          },
          body: JSON.stringify({
            id: '',
            account_number: accountNumber,
            confirm_account_number: confirmAccountNumber,
            ifsc: ifscCode,
            account_holder: accountName,
            bank_name: bankName,
            branch_name: branchName,
            bank_id: selectedBank.bankId
          }),
          credentials: 'include',
        });
      } else {
        const formData = new FormData();
        formData.append('bank_id', selectedBank.bankId.toString());
        formData.append('account_holder', accountName);
        formData.append('mobile', accountNumber);
        if (qrCode) {
          formData.append('qr_code', qrCode);
        }

        response = await fetch(ADD_BANK_DETAILS_URL, {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Accept': 'application/json, text/plain, */*',
          },
          body: formData,
          credentials: 'include',
        });
      }

      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'Bank details added successfully',
          severity: 'success',
        });
        fetchBankDetails(selectedBank.bankId);
      } else {
        setSnackbar({
          open: true,
          message: 'Failed to add bank details',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error adding bank details:', error);
      setSnackbar({
        open: true,
        message: 'An error occurred. Please try again.',
        severity: 'error',
      });
    }

    handleCloseModal();
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const columns: MRT_ColumnDef<BankDetail>[] = [
    { accessorKey: 'user_name', header: 'User Name' },
    { accessorKey: 'account_holder', header: 'Account Holder' },
    { 
      accessorKey: 'account_number', 
      header: 'Account Number',
    },
    { accessorKey: 'ifsc', header: 'IFSC' },
    { 
      accessorKey: 'qr_code', 
      header: 'QR Code',
    },
    // {
    //   accessorKey: 'actions',
    //   header: 'Action',
    //   Cell: ({ row }) => (
    //     <Box>
    //       <Tooltip title="Edit">
    //         <IconButton color="primary">
    //           <Edit />
    //         </IconButton>
    //       </Tooltip>
    //       <Tooltip title="Delete">
    //         <IconButton color="error">
    //           <Delete />
    //         </IconButton>
    //       </Tooltip>
    //     </Box>
    //   ),
    // },
  ];

  const renderFormFields = () => {
    switch (selectedMethod) {
      case 'Paytm':
      case 'GPay':
      case 'PhonePe':
      case 'UPI':
        return (
          <>
            <TextField
              label="UPI ID / Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              fullWidth
            />
            <TextField
              label="Account Name"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              fullWidth
            />
          </>
        );
      case 'Bank Transfer':
        return (
          <>
            <TextField
              label="Account Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Confirm Account Number"
              value={confirmAccountNumber}
              onChange={(e) => setConfirmAccountNumber(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Account Holder Name"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="IFSC Code"
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Bank Name"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Branch Name"
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
              fullWidth
              required
            />
          </>
        );
      case 'Payment Gateway':
        return (
          <TextField
            label="Payment Gateway ID"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            fullWidth
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <StyledToolbar>
        <Typography variant="h4">Bank Details</Typography>
      </StyledToolbar>

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Withdrawal Methods
        </Typography>
        <FullWidthTabs
          value={selectedMethod}
          onChange={(_, newValue) => setSelectedMethod(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {withdrawalMethods.map((method) => (
            <Tab
              key={method.name}
              label={
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                  <img src={method.image} alt={method.name} style={{ width: '100px', height: '50px', marginBottom: '5px', objectFit: 'contain' }} />
                  {method.name}
                </Box>
              }
              value={method.name}
            />
          ))}
        </FullWidthTabs>
      </Paper>

      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5">
            {selectedMethod} Accounts List
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={handleOpenModal}
          >
            Add New
          </Button>
        </Box>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <BaseTable columns={columns} data={bankDetails} />
        )}
      </Paper>

      <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            Add {selectedMethod} Details
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            {renderFormFields()}
            {(selectedMethod !== 'Bank Transfer') && (
              <>
              <input
                accept="image/*"
                style={{ display: 'none' }}
              id="qr-code-upload"
              type="file"
              onChange={(e) => setQrCode(e.target.files?.[0] || null)}
            />
            <label htmlFor="qr-code-upload">
              <Button variant="outlined" component="span" fullWidth startIcon={<AddCircleOutline />}>
                Upload QR Code
              </Button>
            </label>
            </>
            )}
            {qrCode && <Box>File selected: {qrCode.name}</Box>}
            <Button variant="contained" onClick={handleSubmit} fullWidth>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BankDetails;