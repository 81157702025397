
import { configureStore } from '@reduxjs/toolkit';
import websocketReducer from './websocketSlice';
import appSlice from './appSlice';

export const store = configureStore({
    reducer: {
      websocket: websocketReducer,
    appslice:appSlice,
    },
  });
  