interface EnvConfig {
  REACT_APP_TITLE: string;
  REACT_APP_BASE_URL: string;
  REACT_APP_WEBSOCKET_HOST: string;
  // Add other properties as needed
}

  const getEnvValue = (key: string, defaultValue: string): string => {
    if (typeof window !== 'undefined' && window.ENV_CONFIG && key in window.ENV_CONFIG) {
      return window.ENV_CONFIG[key as keyof typeof window.ENV_CONFIG];
    }
    return process.env[key] || defaultValue;
  };
  export const REACT_APP_BASE_URL = getEnvValue('REACT_APP_BASE_URL', 'http://localhost/api/v1');
  export const REACT_APP_FRONTEND_HOST = getEnvValue('REACT_APP_FRONTEND_HOST', 'http://localhost');
  const REACT_APP_WEBSOCKET_HOST = getEnvValue('REACT_APP_WEBSOCKET_HOST', 'ws://localhost');

export const LOGIN_URL = `${REACT_APP_BASE_URL}/user/login`
export const DEPOSIT_WITHDRAWAL_URL = `${REACT_APP_BASE_URL}/user/deposit-withdrawal`

export const ADD_USER_URL =  `${REACT_APP_BASE_URL}/user/create`;
export const LIST_USER_URL =  `${REACT_APP_BASE_URL}/user/just-child`;


export const LIST_RESULT_URL =  `${REACT_APP_BASE_URL}/market/list`;
export const LIST_MARKETS_URL =  `${REACT_APP_BASE_URL}/market/list-for-setting`;
export const LIST_MATCHES_URL = `${REACT_APP_BASE_URL}/match/list-for-setting`;
export const LIST_SERIES_URL = `${REACT_APP_BASE_URL}/series/list-for-setting`;
export const LIST_SPORT_URL = `${REACT_APP_BASE_URL}/sport/list-for-setting`;
export const LIST_BANK_DETAILS_URL = `${REACT_APP_BASE_URL}/user-account/list`;
export const ActiveMatches = `${REACT_APP_BASE_URL}/match/active`;

export const ADD_MARKET_URL = `${REACT_APP_BASE_URL}/market/list-for-setting`;
export const ADD_MATCH_URL = `${REACT_APP_BASE_URL}/match/ADD_DUMMY`;
export const ADD_SERIES_URL = `${REACT_APP_BASE_URL}/series/ADD_DUMMY`;
export const ADD_SPORT_URL = `${REACT_APP_BASE_URL}/series/ADD_DUMMY`;
export const ADD_BANK_DETAILS_URL = `${REACT_APP_BASE_URL}/user-account/create`;
export const DEPOSIT_WITHDRAWAL_REQUEST_LIST_URL = `${REACT_APP_BASE_URL}/deposit-withdrawal-request/list`;
export const DEPOSIT_WITHDRAWAL_REQUEST_UPDATE_URL = `${REACT_APP_BASE_URL}/deposit-withdrawal-request/update`;

export const UPDATE_MARKET_SETTING_URL = `${REACT_APP_BASE_URL}/market/update-market-setting`;
export const UPDATE_MATCH_SETTING_URL = `${REACT_APP_BASE_URL}/match/update-market-setting`;
export const UPDATE_SERIES_SETTING_URL = `${REACT_APP_BASE_URL}/series/update-market-setting`;
export const UPDATE_SITE_SETTING_URL = `${REACT_APP_BASE_URL}/site-setting/update`

export const UPDATE_MARKETS_STATUS_URL = `${REACT_APP_BASE_URL}/market/update/status-by-user-id`;
export const UPDATE_MATCH_STATUS_URL = `${REACT_APP_BASE_URL}/match/update/status-by-user-id`;
export const UPDATE_SERIES_STATUS_URL = `${REACT_APP_BASE_URL}/series/update/status-by-user-id`;
export const UPDATE_SPORT_SETTING_URL = `${REACT_APP_BASE_URL}/sport/update/status-by-user-id`;

export const DECLARE_RESULT_URL = `${REACT_APP_BASE_URL}/result/market-result`;
export const LIST_RESULTS= `${REACT_APP_BASE_URL}/result/resulted-market`
export const ROLLBACK_RESULT_URL = `${REACT_APP_BASE_URL}/result/rollback-result`;

export const CREDIT_LINE_URL = `${REACT_APP_BASE_URL}/user/update-credit-limit`
export const PASSWORD_CHANGE_URL = `${REACT_APP_BASE_URL}/user/update-child-password`
export const MATCH_BET_LOCK_URL =`${REACT_APP_BASE_URL}/user/bet-lock`
export const FANCY_BET_LOCK_URL =`${REACT_APP_BASE_URL}/user/fancy-lock`
export const REGISTER_LOCK_URL =`${REACT_APP_BASE_URL}/user/register-lock`
export const USER_LOCK_URL =`${REACT_APP_BASE_URL}/user/user-lock`
export const BALANCE_URL =`${REACT_APP_BASE_URL}/user/balance`
export const BET_HISTORY_URL =`${REACT_APP_BASE_URL}/bet/bet-history`
export const DASHBOARD_URL =`${REACT_APP_BASE_URL}/user/dashboard`
export const GET_PROFIT_LOSS_USERWISE_URL = `${REACT_APP_BASE_URL}/account/get-pl-user-wise`
export const GET_PROFIT_LOSS_SPORT_WISE_URL =  `${REACT_APP_BASE_URL}/account/get-pl-sport-wise`
export const GET_PROFIT_LOSS_URL = `${REACT_APP_BASE_URL}/account/get-pl`
export const GET_LOGIN_HISTORY_URL = `${REACT_APP_BASE_URL}/user/login-history`
export const GET_USER_SETTINGS_URL = `${REACT_APP_BASE_URL}/user/user-setting`
export const ACCOUNT_STATEMENT_URL = `${REACT_APP_BASE_URL}/account/getStatement`
export const SEARCH_USER_URL = `${REACT_APP_BASE_URL}/user/search-user`
export const BET_HISTORY_MARKET_ANALYSIS_URL = `${REACT_APP_BASE_URL}/bet/bet-history-detail`
// export const JUST_CHILD_URL = `${REACT_APP_BASE_URL}/user/just-child`


export const BANNER_API_URLS = {
      CREATE: `${REACT_APP_BASE_URL}/banner/create`,
      LIST: `${REACT_APP_BASE_URL}/banner/list`,
      DELETE: `${REACT_APP_BASE_URL}/banner/delete`,
};