import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  availableStakes: [100, 200, 400],
  betSlip: [],
  isUser: false, // Add this new state
}

const appSlice = createSlice({
  name: 'appslice',
  initialState,
  reducers: {
    updateLoadingStatus: (state, action) => {
      state.isLoading = action.payload
    },
    updateAvailableStakes: (state, action) => {
      const newValue = action.payload.newValue
      const index = action.payload.index
      if (!isNaN(newValue) && newValue > 0) {
        if (index === -1) {
          state.availableStakes = [
            ...state.availableStakes,
            action.payload.newValue,
          ]
        } else {
          const newStakes = [...state.availableStakes]
          newStakes[index] = newValue
          state.availableStakes = newStakes
        }
      }
    },
    deleteAvailableStakes: (state, action) => {
      const index = action.payload.index

      const newStakes = state.availableStakes.filter((_, i) => i !== index)

      state.availableStakes = newStakes
    },
    updateBetSlip: (state, action) => {
      const { index, side, odds, market,betType } = action.payload;
      const existingBetIndex = state.betSlip.findIndex(bet => bet.index === index && bet.side === side);

      if (existingBetIndex !== -1) {
        // Remove the bet if it already exists
        // state.betSlip = state.betSlip.filter((_, i) => i !== existingBetIndex);
        state.betSlip=[]
      } else {
        // Add the new bet
        // state.betSlip.push({ index, side, odds, market });
        const newBet = { index, side, odds, market,betType }
        state.betSlip=[newBet]
      }
    },
    deleteBetSlip: (state, action) => {
      state.betSlip = []
    },
    setUserData: (state, action) => {
      const { user_type_id } = action.payload;
      state.isUser = user_type_id === 10;
      // You can set other user-related data here if needed
    },
  },
})

export const {
  updateAvailableStakes,
  updateLoadingStatus,
  deleteAvailableStakes,
  updateBetSlip,
  deleteBetSlip,
  setUserData, // Export the new action
} = appSlice.actions

export default appSlice.reducer
