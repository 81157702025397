import React, { useState } from 'react';
import { Typography, Box, TextField, Button, Paper, Divider } from '@mui/material';
import UserLockToggle from './UserLockToggle';
import MatchOdssLockToggle from './MatchOdssLockToggle';
import FancyLockToggle from './FancyLockToggle';

interface UserBetLockModalProps {
  userData: {
    userName: string;
    userId: string;
    uSt: boolean;
    mSt: boolean;
    fSt: boolean;
  };
}

function UserBetLockModal({ userData }: UserBetLockModalProps) {
  const [transactionPassword, setTransactionPassword] = useState('');

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionPassword(event.target.value);
  };

  // Add save handler
  const handleSave = () => {
    // Implement save logic here
    console.log('Saving changes...');
    // You can add API calls or other logic to save the changes
  };

  return (
    <>
      <Typography variant="subtitle1" gutterBottom align="center" color="text.secondary">
        {userData.userName}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <LockToggleRow
          label="User Lock"
          component={
            <UserLockToggle
              transactionPassword={transactionPassword}
              lockStatus={Boolean(Number(userData.uSt))}
              userId={userData.userId}
            />
          }
        />
        <LockToggleRow
          label="Match Odds Lock"
          component={
            <MatchOdssLockToggle
              transactionPassword={transactionPassword}
              lockStatus={Boolean(Number(userData.mSt))}
              userId={userData.userId}
            />
          }
        />
        <LockToggleRow
          label="Fancy Lock"
          component={
            <FancyLockToggle
              transactionPassword={transactionPassword}
              lockStatus={Boolean(Number(userData.fSt))}
              userId={userData.userId}
            />
          }
        />
      </Box>
      <TextField
        label="Transaction Password"
        type="password"
        value={transactionPassword}
        onChange={handlePasswordChange}
        fullWidth
        margin="normal"
        sx={{ mt: 4 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        disabled={!transactionPassword}
        fullWidth
        sx={{ mt: 3 }}
      >
        Save Changes
      </Button>
    </>
  );
}

interface LockToggleRowProps {
  label: string;
  component: React.ReactNode;
}

function LockToggleRow({ label, component }: LockToggleRowProps) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Typography variant="subtitle1">{label}:</Typography>
      {component}
    </Box>
  );
}

export default UserBetLockModal;
