import React, { useState } from 'react';
import { toast } from 'react-toastify';
import TransactionForm, { TransactionFormProps } from './TransactionForm';
import { FormProps } from './type/type';
import { useApi } from '../../hooks/useApi';
import { DEPOSIT_WITHDRAWAL_URL } from '../../utils/apiUrl';
import { FormikValues } from 'formik';

const DepositForm: React.FC<FormProps> = ({ userName, balance, onClose, userData,userRole }) => {
  const { sendRequest } = useApi();

  const handleFormSubmit = async (values: FormikValues) => {
    try {
      const response = await sendRequest(DEPOSIT_WITHDRAWAL_URL, 'POST',
        {
          user_id: userData.userId,
          type: 1, // Assuming 1 is for deposit
          amount: Number(values.amount),
          remark: values.remark || null,
          master_password: values.masterPassword,
        },
      );

      if (response.status === 200) {
        toast.success(response.message,{
          position: 'top-center',
          autoClose: 2000
        });
        onClose();
      } else {
        toast.error(response.message || 'Deposit failed. Please try again.',{
          position: 'top-center',
          autoClose: 2000
        });
      }
    } catch (error) {
      console.error('Deposit error:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <TransactionForm
      userName={userName}
      userRole={userRole}
      balance={balance}
      onClose={onClose}
      onSubmit={(values) => handleFormSubmit(values)}
      formType='Deposit'
    />
  );
};

export default DepositForm;