import React, { useState, useCallback } from 'react';
import { Field } from 'formik';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import { SEARCH_USER_URL } from '../../utils/apiUrl';

interface UserSearchProps {
  field: {
    key: string;
    label: string;
    placeholder?: string;
  };
  isUserSearch?: boolean;
}

interface User {
  value: string;
  label: string;
}

const UserSearch: React.FC<UserSearchProps> = ({ field ,isUserSearch=false}) => {
  const [options, setOptions] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  const searchUsers = async (inputValue: string) => {
    if (inputValue.length < 2) {
      setOptions([]);
      return;
    }

    setLoading(true);
    try {
        const token = await sessionStorage.getItem('adminAuthToken')
      const response = await fetch(SEARCH_USER_URL, {
        method: 'POST',
        headers: {
          'Authorization': token || '',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: 8788, name: inputValue }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      
      // Assuming the response data is an array of users with id and name properties
      const users: User[] = data?.data?.map((user: any) => ({
        value: user.id.toString(),
        label: user.name
      }));
      console.log(users)
      setOptions(users);
    } catch (error) {
      console.error('Error searching users:', error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  // Debounce the search function
  const debouncedSearchUsers = useCallback(
    debounce(searchUsers, 300),
    []
  );

  return (
    <Box sx={{minWidth:'200px'}}>
      <Typography variant="body2">{field.label}</Typography>
      <Field name={field.key}>
        {({ field: { value }, form: { setFieldValue } }: { field: { value: string }, form: { setFieldValue: (field: string, value: string) => void } }) => (
          <Autocomplete
            options={options}
            loading={loading}
            getOptionLabel={(option: User) => option.label}
            onChange={(_, newValue) => {
              setFieldValue(field.key, newValue ? ( isUserSearch ? newValue.label : newValue.value) : '');
            }}
            onInputChange={(_, newInputValue) => {
              debouncedSearchUsers(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder={field.placeholder}
                sx={{ bgcolor: 'white', borderRadius: 1 }}
              />
            )}
          />
        )}
      </Field>
    </Box>
  );
};

export default UserSearch;
