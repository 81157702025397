import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  styled,
  CircularProgress,
  Toolbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ADD_ACCOUNT_CONFIG } from "./config";
import { useApi } from "../../../hooks/useApi";
import { ADD_USER_URL, GET_USER_SETTINGS_URL } from "../../../utils/apiUrl";
import theme from "../../../utils/customTheme";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux"; // Add this import
import SiteSettings from "../../settings/SiteSettings";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.bgColor,
  color: "white",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: theme.palette.primary.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
  padding: theme.spacing(1, 3),
  fontSize: "0.6 rem",
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const SectionDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

const ScrollableContent = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 64px)", // Adjust this value based on your toolbar height
  overflowY: "auto",
  padding: theme.spacing(3),
}));

const formSections = [
  {
    title: "User Information",
    fields: [
      "name",
      "loginId",
      "password",
      "phoneNumber",
      "creditLimit",
      "role",
    ],
  },
  // {
  //   title: 'Partnership',
  //   fields: ['uplinePartnership', 'downlinePartnership', 'ourPartnership']
  // },
  {
    title: "Sports Settings",
    fields: ["sports"],
  },
  {
    title: "Submit Details",
    fields: ["transactionPassword"],
  },
];

const AddUser: React.FC = () => {
  const { isLoading, sendRequest } = useApi();
  const [role, setRole] = useState("");

  const [userSettings, setUserSettings] = useState([]);
  const token = sessionStorage.getItem("adminAuthToken");
  const userDetails = JSON.parse(
    sessionStorage.getItem("adminUserDetails") || "{}"
  );
  const userId = userDetails.id || null;
  const userType = userDetails.user_type_id || null;
  const isTechAdmin = userType === 1;
  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const response = await fetch(GET_USER_SETTINGS_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          },
          body: JSON.stringify({ user_id: userId }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user settings");
        }

        const data = await response.json();

        const formattedData = data?.data
          .map((item: any) => ({
            partnership: item.partnership || "0",
            sportId: item.sport_id,
            sportName: item.name,
            betDelay: item.bet_delay || 0,
            sessionDelay: item.session_delay || 0,
            bookmakerDelay: item.bookmaker_delay || 0,
            oddsMinStack: item.odds_min_stack || 0,
            sessionMinStack: item.session_min_stack || 0,
            bookmakerMinStack: item.bookmaker_min_stack || 0,
            oddsMaxStack: item.odds_max_stack || 0,
            bookmakerMaxStack: item.bookmaker_max_stack || 0,
            sessionMaxStack: item.session_max_stack || 0,
            commissionType: "0",
            matchCommission: parseFloat(item.match_commission) || 0,
            matchCommissionType: item.match_commission_type || 0,
            sessionCommission: parseFloat(item.session_commission) || 0,
            sessionCommissionType: item.session_commission_type || 0,
            bookmakerCommission: parseFloat(item.bookmaker_commission) || 0,
            bookmakerCommissionType: item.bookmaker_commission_type || 0,
          }))
          .filter((sport: any) => sport.sportName == "Cricket");

        setUserSettings(formattedData);
      } catch (error) {
        console.error("Error fetching user settings:", error);
        toast.error("Failed to fetch user settings. Please try again.");
      }
    };

    fetchUserSettings();
  }, [token, userId]);

  const initialValues = {
    name: "",
    loginId: "",
    password: "",
    phoneNumber: "",
    creditLimit: 0,
    role: "",
    // uplinePartnership: ADD_ACCOUNT_CONFIG.DEFAULT_VALUES.uplinePartnership,
    // downlinePartnership: ADD_ACCOUNT_CONFIG.DEFAULT_VALUES.downlinePartnership,
    transactionPassword: "",
    // sports: userSettings.map((sport: any) => ({
    //   partnership: sport.partnership.toString(),
    //   sportId: sport.sportId,
    //   sportName: sport.sportName,
    //   betDelay: sport.betDelay,
    //   sessionDelay: sport.sessionDelay,
    //   bookmakerDelay: sport.bookmakerDelay,
    //   oddsMinStack: sport.oddsMinStack,
    //   sessionMinStack: sport.sessionMinStack,
    //   bookmakerMinStack: sport.bookmakerMinStack,
    //   oddsMaxStack: sport.oddsMaxStack,
    //   bookmakerMaxStack: sport.bookmakerMaxStack,
    //   sessionMaxStack: sport.sessionMaxStack,
    //   commissionType: sport.commissionType,
    //   matchCommission: sport.matchCommission,
    //   matchCommissionType: sport.matchCommissionType,
    //   sessionCommission: sport.sessionCommission,
    //   sessionCommissionType: sport.sessionCommissionType,
    //   bookmakerCommission: sport.bookmakerCommission,
    //   bookmakerCommissionType: sport.bookmakerCommissionType,
    // })),
    sports:userSettings,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    loginId: Yup.string().required("Required"),
    password: Yup.string()
      .min(
        ADD_ACCOUNT_CONFIG.VALIDATION.PASSWORD_MIN_LENGTH,
        `Password must be at least ${ADD_ACCOUNT_CONFIG.VALIDATION.PASSWORD_MIN_LENGTH} characters`
      )
      .required("Required"),
    phoneNumber: Yup.string()
      .matches(
        ADD_ACCOUNT_CONFIG.VALIDATION.PHONE_REGEX,
        "Invalid phone number"
      )
      .required("Required"),
    creditLimit: Yup.number().min(0).required("Required"),
    role: Yup.string().required("Required"),
    transactionPassword: Yup.string().required("Required"),
    sports: Yup.array().of(
      Yup.object().shape({
        betDelay: isTechAdmin
          ? Yup.number().integer().min(0).required("Required")
          : Yup.number().strip(),
        sessionDelay: isTechAdmin
          ? Yup.number().integer().min(0).required("Required")
          : Yup.number().strip(),
        bookmakerDelay: isTechAdmin
          ? Yup.number().integer().min(0).required("Required")
          : Yup.number().strip(),
        oddsMaxStack: isTechAdmin
          ? Yup.number().integer().min(0).required("Required")
          : Yup.number().strip(),
        oddsMinStack: isTechAdmin
          ? Yup.number().integer().min(0).required("Required")
          : Yup.number().strip(),
        sessionMaxStack: isTechAdmin
          ? Yup.number().integer().min(0).required("Required")
          : Yup.number().strip(),
        bookmakerMaxStack: isTechAdmin
          ? Yup.number().integer().min(0).required("Required")
          : Yup.number().strip(),
        sessionMinStack: isTechAdmin
          ? Yup.number().integer().min(0).required("Required")
          : Yup.number().strip(),
        bookmakerMinStack: isTechAdmin
          ? Yup.number().integer().min(0).required("Required")
          : Yup.number().strip(),
        partnership: Yup.number()
          .min(0, "Partnership cannot be less than 0")
          .max(100, "Partnership cannot be greater than 100")
          .required("Required"),
        commissionType: Yup.string()
          .oneOf(["0", "1", "2"])
          .required("Required"),
        matchCommission: Yup.number().min(0).max(100).required("Required"),
        sessionCommission: Yup.number().min(0).max(100).required("Required"),
        bookmakerCommission: Yup.number().min(0).max(100).required("Required"),
      })
    ),
  });

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting, resetForm }: any
  ) => {
    try {
      const payload = {
        user_name: values.loginId,
        password: values.password,
        master_password: values.transactionPassword,
        name: values.name,
        mobile: values.phoneNumber,
        user_type_id: values.role,
        parent_id: 0,
        verify_otp: "123456",
        credit_limit: String(values.creditLimit),
        settings: values.sports.map((sport: any) => ({
          sport_id: sport.sportId,
          partnership: sport.partnership,
          bet_delay: isTechAdmin ? sport.betDelay : "0",
          session_delay: isTechAdmin ? sport.sessionDelay : "0",
          bookmaker_delay: isTechAdmin ? sport.bookmakerDelay : "0",
          odds_max_stack: isTechAdmin ? sport.oddsMaxStack : "0",
          odds_min_stack: isTechAdmin ? sport.oddsMinStack : "0",
          session_max_stack: isTechAdmin ? sport.sessionMaxStack : "0",
          bookmaker_max_stack: isTechAdmin ? sport.bookmakerMaxStack : "0",
          session_min_stack: isTechAdmin ? sport.sessionMinStack : "0",
          bookmaker_min_stack: isTechAdmin ? sport.bookmakerMinStack : "0",
          match_commission: sport.matchCommission,
          session_commission: sport.sessionCommission,
          bookmaker_commission: sport.bookmakerCommission,
        })),
      };

      const response = await sendRequest(ADD_USER_URL, "POST", payload);

      if (response.status === 200 || response.status === 201) {
        toast.success(response.message, {
          position: "top-center",
          autoClose: 5000,
        });
        resetForm();
      } else {
        toast.error(response.message, {
          position: "top-center",
          autoClose: 5000,
        });
      }
    } catch (error: any) {
      console.error("Error submitting form:", error.message);
      toast.error("An error occurred. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const renderSportFields = (
    section: string,
    touched: any,
    errors: any,
    values: any,
    initialValues: any
  ) => {
    const sectionFields: Record<string, string[]> = {
      "Commission Settings": [
        "matchCommission",
        "sessionCommission",
        "bookmakerCommission",
      ],
      "Partnership Settings": ["partnership"],
      "Min/Max Bet Settings": [
        "oddsMinStack",
        "sessionMinStack",
        "bookmakerMinStack",
        "oddsMaxStack",
        "sessionMaxStack",
        "bookmakerMaxStack",
      ],
      "Bet Delay Settings": ["betDelay", "sessionDelay", "bookmakerDelay"],
    };

    return values.sports.map((sport: any, sportIndex: number) => (
      <StyledAccordion key={`${section}-${sport.sportId}`}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{sport.sportName}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ mt: 2 }}>
          <Grid container spacing={2} >
            {sectionFields[section].map((key) => (
              <StyledGridItem item xs={12} sm={6} md={4} key={key} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'start'}}>
                {key === 'partnership' && (
                  <Typography sx={{marginBottom:'20px'}}>
                    Upline: {initialValues.sports[sportIndex].partnership}
                  </Typography>
                )}
                <Field
                  as={TextField}
                  fullWidth
                  name={`sports[${sportIndex}].${key}`}
                  label={
                    key.charAt(0).toUpperCase() +
                    key.slice(1).replace(/([A-Z])/g, " $1")
                  }
                  type={
                    key.includes("Type") ||
                    key.includes("Delay") ||
                    key.includes("Stack")
                      ? "number"
                      : "text"
                  }
                  error={
                    touched.sports?.[sportIndex]?.[key] &&
                    !!errors.sports?.[sportIndex]?.[key]
                  }
                  helperText={
                    touched.sports?.[sportIndex]?.[key] &&
                    errors.sports?.[sportIndex]?.[key]
                  }
                />
              </StyledGridItem>
            ))}
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    ));
  };

  const renderField = (
    fieldName: string,
    touched: any,
    errors: any,
    values: any,
    setFieldValue: any,
    initialValues: any
  ) => {
    if (fieldName === "role") {
      return (
        <FormControl fullWidth error={touched[fieldName] && !!errors[fieldName]}>
          <InputLabel id={`${fieldName}-label`}>Select Role</InputLabel>
          <Field
            as={Select}
            labelId={`${fieldName}-label`}
            label="Select Role"
            name={fieldName}
            error={touched[fieldName] && !!errors[fieldName]}
            onChange={(e: any) => {
              setRole(e.target.value);
              setFieldValue(fieldName, e.target.value);
            }}
            value={values[fieldName]}
          >
            <MenuItem value="">Select Role</MenuItem>
            {ADD_ACCOUNT_CONFIG.ACCOUNT_TYPES.filter((type) => type.value > userType).map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Field>
          {touched[fieldName] && errors[fieldName] && (
            <Typography color="error" variant="caption">
              {errors[fieldName]}
            </Typography>
          )}
        </FormControl>
      );
    }

    if (fieldName === "sports") {
      // if(role==='10'){
      //   return <>
      //   <SectionTitle variant="h6">Min/Max Bet Settings</SectionTitle>
      //     {renderSportFields('Min/Max Bet Settings', touched, errors, values)}

      //     <SectionTitle variant="h6">Bet Delay Settings</SectionTitle>
      //     {renderSportFields('Bet Delay Settings', touched, errors, values)}
      //   </>
      // }
      return (
        <>
          <SectionTitle variant="h6">Commission Settings</SectionTitle>
          {renderSportFields("Commission Settings", touched, errors, values, initialValues)}

          <SectionTitle variant="h6">Partnership Settings</SectionTitle>
          {renderSportFields("Partnership Settings", touched, errors, values, initialValues)}
          {isTechAdmin && (
            <>
              <SectionTitle variant="h6">Min/Max Bet Settings</SectionTitle>
              {renderSportFields(
                "Min/Max Bet Settings",
                touched,
                errors,
                values,
                initialValues
              )}

              <SectionTitle variant="h6">Bet Delay Settings</SectionTitle>
              {renderSportFields("Bet Delay Settings", touched, errors, values, initialValues)}
            </>
          )}
        </>
      );
    }

    return (
      <Field
        as={TextField}
        fullWidth
        name={fieldName}
        label={
          fieldName.charAt(0).toUpperCase() +
          fieldName.slice(1).replace(/([A-Z])/g, " $1")
        }
        type={
          fieldName.includes("password")
            ? "password"
            : fieldName.includes("Limit") ||
              fieldName.includes("Bet") ||
              fieldName.includes("Delay")
            ? "number"
            : "text"
        }
        error={touched[fieldName] && !!errors[fieldName]}
        helperText={touched[fieldName] && errors[fieldName]}
      />
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <ScrollableContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            values,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <StyledToolbar>
                <Typography variant="h4">Add Account</Typography>
              </StyledToolbar>
              <StyledPaper elevation={3}>
                {formSections.map((section, index) => (
                  <React.Fragment key={index}>
                    <SectionHeader variant="h6">{section.title}</SectionHeader>
                    {section.title !== "Sports Settings" ? (
                      <StyledGrid container spacing={3}>
                        {section.fields.map((fieldName) => (
                          <StyledGridItem
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={fieldName}
                          >
                            {renderField(
                              fieldName,
                              touched,
                              errors,
                              values,
                              setFieldValue,
                              initialValues
                            )}
                          </StyledGridItem>
                        ))}
                      </StyledGrid>
                    ) : (
                      renderField(
                        "sports",
                        touched,
                        errors,
                        values,
                        setFieldValue,
                        initialValues
                      )
                    )}
                    {section.title === "Submit Details" && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems:'center',
                          mt: 2,
                        }}
                      >
                        <StyledButton
                          variant="contained"
                          size="large"
                          type="submit"
                          sx={{
                            background: theme.palette.buttonSecondaryColor,
                            color: "white",
                          }}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Submit"
                          )}
                        </StyledButton>
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </StyledPaper>
            </Form>
          )}
        </Formik>
      </ScrollableContent>
    </Box>
  );
};

export default AddUser;
